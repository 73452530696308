<template>
  <div class="Assignform">
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <!-- Sidebar Start -->
      <SideBar />
      <NavBar />
      <br><br>
      <div class="App">
        <div class="container">
          <h2>Şoför Atama Formu</h2>
          <form @submit.prevent="assingCreate">
            <div class="form-group">
              <label for="vehicle">Araç Seç:</label>
              <select v-model="information.carID" required>
                <option value="">Select Vehicle</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.id">{{ vehicle.plaka }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="driver">Şoför Seç: (Ad Soyad - T.C. Kimlik Numarası)</label>
              <select v-model="information.driverID" required>
                <option value="">Select User</option>
                <option v-for="driver in drivers" :key="driver.id" :value="driver.id">{{ driver.name + ' ' + driver.lastName
                  + ' - ' + driver.identyID }}</option>
              </select>
            </div>

            <button type="submit">Şoför Ata</button>
            <div class="form-group">
              <a id="successText">{{ this.successText }}</a>
              <a id="errorText">{{ this.errorText }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
export default {
  components: {
    SideBar,
    NavBar,
  },
  data() {
    return {
      drivers: [],
      vehicles: [],
      information: {
        driverID: '',
        carID: '',
      },
      errorText: null,
      successText: null,
    }
  },
  mounted() {
    this.getDriver()
    this.getVehicles()
  },
  methods: {
    getDriver() {
      axios.get('drivers', { headers: { 'X-Requested-With': 'driverAssign' } })
        .then(response => {
          if (response.status === 200) {
            this.drivers = response.data.data;
          }          
        })
        .catch(error => {
          console.log(error.message);
        }); 
    },
    getVehicles() {
      axios.get('get-vehicle', { headers: { 'X-Requested-With': 'driverAssign' } })
        .then(response => {
          if(response.status === 200)
          {
            this.vehicles = response.data.data;
          }          
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    assingCreate() {
      axios.post('driver-assing', this.information)
        .then(
          ({ data }) => {
              if (data.status === true) {
                this.errorText = null
                this.successText = data.message
              } else {
                this.successText = null
                this.errorText = data.message
              }
          }
        )
        .catch(error => {
          this.errorText= error.message
        })
    }
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}
</style>
