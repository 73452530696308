<template>
    <div class="limiter">
        <div class="container-login100" style="background-image: url('images/bg-01.jpg');">
            <div class="wrap-login100">
                <form class="login100-form validate-form" @submit.prevent="submitForm">
                    <span class="login100-form-logo">
                        <img src="images/logo.png" height="125px">
                    </span>
                    <br>

                    <span class="login100-form-title p-b-34 p-t-27">
                        ADMİN OLUŞTUR
                    </span>
                    <br>
                    <div class="wrap-input100 validate-input" data-validate="Enter username">
                        <input v-model="user.name" class="input100" type="text" name="username" placeholder="Kullanıcı Adı"
                            required>
                        <span class="focus-input100" data-placeholder="&#xf207;"></span>
                    </div>
                    <div class="wrap-input100 validate-input" data-validate="Enter username">
                        <input v-model="user.email" class="input100" type="email" name="username"
                            placeholder="E-mail Adresi" required>
                        <span class="focus-input100" data-placeholder="&#xf207;"></span>
                    </div>

                    <div class="wrap-input100 validate-input" data-validate="Enter password">
                        <input v-model="user.password" class="input100" :type="passwordVisible ? 'text' : 'password'"
                            name="pass" placeholder="Parola" required>
                        <span class="focus-input100" data-placeholder="&#xf191;"></span>
                    </div>

                    <div class="wrap-input100 validate-input" data-validate="Enter password">
                        <input v-model="user.confirmPassword" class="input100" :type="passwordVisible ? 'text' : 'password'"
                            name="pass" placeholder="Parolanızı Tekrar Yazınız" required>
                        <span class="focus-input100" data-placeholder="&#xf191;"></span>
                    </div>
                    <label for="showPasswordCheckbox" id="showPassword">
                        Parolayı Göster
                        <input type="checkbox" id="showPasswordCheckbox" v-model="passwordVisible">
                    </label>
                    <br>
                    <div>
                        <a id="success">{{ this.successText }}</a>
                        <a id="error">{{ this.errorText }}</a>
                    </div>
                    <br>
                    <div class="container-login100-form-btn">
                        <router-link to="/admin" class="sidebar-link" aria-expanded="false"><button style="float:left" class="login100-form-btn">Admin Panel</button></router-link>
                        <button style="float:right" type="submit" class="login100-form-btn">Kaydet</button>                        
                    </div>
                </form>
            </div>
        </div>
        <div id="dropDownSelect1"></div>
    </div>
</template>
<script>
import axios from '@/server/axios'
export default {
    data() {
        return {
            user: {
                name: '',
                email: '',
                password: '',
                confirmPassword: ''
            },
            passwordVisible: false,
            errorText: '',
            successText: ''
        }
    },
    methods: {
        async submitForm() {

            axios.post('register', this.user)
                .then(
                    response => {
                        this.successText = null
                        this.errorText = null
                        this.submitValue = 'Please wait..'
                        if (response.status === 200) {
                            this.successText = 'Kayıt Başarılı'
                        }
                        else if(response.status === 203) {
                            this.errorText = response.data.message
                        }
                    }
                )
                .catch(error => {
					this.errorText = error.message
				})
        }
    }
}
</script>
<style scoped>
#showPassword {
    color: white;
}
#success {
    color:green;
}
#error {
    color: red;
}
</style>