<template>
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <SideBar />
    <NavBar />
    <div id="map-blog">
      <div id="map-menu">
        <br>
        <!-- Last  Location -->
        <form @submit.prevent="live">
          <div class="row">
            <div class="col-md-12">
              <h2>Son Lokasyon</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label">Araçlar</label>
              <select class="form-select" v-model="oneCarSelect" required="" autofocus="" style="background-color:white;">
                <option value="" selected disabled>Araç Seç</option>
                <option value="0">Tüm Araçlar</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}</option>
              </select>
            </div>
          </div>
          <br>
          <button class="btn btn-lg btn-primary btn-block" type="submit" style="float: center; width:100%">Lokasyonu
            Göster</button>
          <br><br>
          <div class="form-group">
            <a id="errorText">{{ this.errorTextLive }}</a>
          </div>
        </form>
        <br>
        <!-- One Day Route -->
        <form @submit.prevent="route">
          <div class="row">
            <div class="col-md-12">
              <h2>Tarih Bazlı Rota Bilgisi</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label">Araçlar</label>
              <select class="form-select" v-model="routeCarSelect.deviceID" required="" autofocus=""
                style="background-color:white;">
                <option value="" selected disabled>Araç Seçiniz</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.deviceID">{{ vehicle.plaka }}
                </option>
              </select>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label for="startDate" class="form-label"></label>
              <VueDatePicker v-model="routeCarSelect.date" :enable-time-picker="false" locale="tr" cancel-text="İptal"
                select-text="Seç" required="" />
            </div>
          </div>
          <br>
          <button class="btn btn-lg btn-primary btn-block" type="submit" style="float: center; width:100%">Rotayı
            Göster</button>
          <br><br>
          <div class="form-group">
            <a id="errorText">{{ this.errorTextRoute }}</a>
          </div>
        </form>
        <br>
        <!-- Time Range Route -->
        <form @submit.prevent="timeRangeRouteMethod">
          <div class="row">
            <div class="col-md-12">
              <h2>Saat Bazlı Rota Bilgisi</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label">Araçlar</label>
              <select class="form-select" v-model="timeRangeRoute.deviceID" required="" autofocus=""
                style="background-color:white;">
                <option value="" selected disabled>Araç Seçiniz</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.deviceID">{{ vehicle.plaka }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="startDate" class="form-label">Tarih</label>
              <VueDatePicker v-model="timeRangeRoute.date" :enable-time-picker="false" locale="tr" cancel-text="İptal"
                select-text="Seç" required="" date-picker />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="startDate" class="form-label">Balangıç Saati</label>
              <VueDatePicker v-model="timeRangeRoute.startTime" locale="tr" cancel-text="İptal" select-text="Seç"
                required="" :max-time="timeRangeRoute.finishTime" time-picker />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="startDate" class="form-label">Bitiş Saati</label>
              <VueDatePicker v-model="timeRangeRoute.finishTime" locale="tr" cancel-text="İptal" select-text="Seç"
                required="" :min-time="timeRangeRoute.startTime" time-picker />
            </div>
          </div>
          <br>
          <button class="btn btn-lg btn-primary btn-block" type="submit" style="float: center; width:100%">Lokasyonu
            Göster</button>
          <br><br>
          <div class="form-group">
            <a id="errorText">{{ this.errorTimeRangeRoute }}</a>
          </div>
        </form>
      </div>
      <GoogleMap id="map" :api-key="key" :map-id="mapID" :center="center" :zoom="5" :clickable-icons="false"
        :fullscreen-control="true" language="tr-TR" mapTypeId="roadmap">
        <Polyline v-if="this.polyline" :options="flightPath" />
        <Marker v-if="this.polyline" :options="startMarker"></Marker>
        <Marker v-if="this.polyline" :options="finishMarker"></Marker>
        <CustomMarker v-if="this.marker" :options="{ position: center, anchorPoint: 'BOTTOM_CENTER', }">
          <div style="text-align: center">
            <div style="font-size: 1.125rem; background-color:#3673A4; color:white;">{{ this.plate }}<br>{{ this.time }}
            </div>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAklEQVR4nO2WT08TQRjGe9Iv4IEjV1tLqzSRELU7IweIFz1AQBPCJxCvSEMC0oQYYqIxxrQmhZ01Jh7gO0g8qPQCBbRVCQVb6ba02/InaRNeMt3+2S7dQlu6u5p9kue0aef5zbzvvGMyGTJkyJChVsvp22/DrOBBJLWDSQqqO1nd3J6CE1WNOH4Hk7jH6dttu5jw79NXEJsKYiKA6NYCYC6eN+LiP+jaTQMgIszQ4Hc8PNyYCIJtbA1sT0V3yGzNO1Dha+Nyr4JFZnPRrlWwTq7B7XeRAgTvbhoAE2GTAnRO/QTb2HrLAcyuFbC5v4snQfjNpsI7fcl2Gp6ZS4I9H14dAItrBZj5WB7C+SHZ3vjus8IIBbj15m8hvDoAZtcKdL/dKfbDSMMAiKR8FKBrNqw6gOPFb7EPCO9rvv4nQ6oDWKfXm+sDaf1fL4VXD8B83j7AJPW5dI+L9/M25hJ90vq3awBwVdYHDBHuiYO0lJOW1xItk/IgKn8IS+tfKwCHpA8wK2zLc9JvJqUP0vrXCsAq6QOljTZhTujDbGq7CgCgQv1rBWCW9IEcAJF4+C6b6D3VE2guY0EkfVB++8itzlsInzJ/hNhdW82bp/clXMZECGCSBh0CACaxQP9HuFTjRhJGxfA6BeB4YLjYY0UAxKa/6h0AkdgXZYB87ZcBhhYy4I/m4Ch3DP5IDgYXMi0HGFrcA380K64ZzcLg4l4lAMfv1yihYngRgIaXajmSazmAP5qVrZmtAKA+N8Bh7rjizw6yxy0HOKy2ZqMAtGz+6RMYWsjkQ9Nd+aZiDyxHsoU1T/dAXQBn3UKdU8VnduVklk7kahNYOnU7pjfquoUuFKD71Z+mAbpeh7UDQPNJsI9vNAxgmVgFho1rB0B98/lWwwCO2V91D7ILB2B8CXA8C4G9DgBquzsIzDyvPYDaTwn8XwP0eLd0D9DjDSkD3H8yA2UI/QH0eEPwYNStDDDwaBhq2aSSBhrNYQDo4ASWlH7U/3D4k4oAS3rIYciQIUOmM3UCul/C/JAqQk8AAAAASUVORK5CYII="
              width="30" height="30" style="margin-top: 8px">

          </div>
        </CustomMarker>
        <CustomMarker v-if="this.multiMarker" v-for="(location, i) in this.locations"
          :options="{ position: location, anchorPoint: 'BOTTOM_CENTER' }" :key="i">
          <div style="text-align: center">
            <div style="font-size: 1.125rem; background-color:#3673A4; color:white;">{{ this.multiLine[i].plate
            }}<br> Saat: {{ this.multiLine[i].time }}
            </div>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAklEQVR4nO2WT08TQRjGe9Iv4IEjV1tLqzSRELU7IweIFz1AQBPCJxCvSEMC0oQYYqIxxrQmhZ01Jh7gO0g8qPQCBbRVCQVb6ba02/InaRNeMt3+2S7dQlu6u5p9kue0aef5zbzvvGMyGTJkyJChVsvp22/DrOBBJLWDSQqqO1nd3J6CE1WNOH4Hk7jH6dttu5jw79NXEJsKYiKA6NYCYC6eN+LiP+jaTQMgIszQ4Hc8PNyYCIJtbA1sT0V3yGzNO1Dha+Nyr4JFZnPRrlWwTq7B7XeRAgTvbhoAE2GTAnRO/QTb2HrLAcyuFbC5v4snQfjNpsI7fcl2Gp6ZS4I9H14dAItrBZj5WB7C+SHZ3vjus8IIBbj15m8hvDoAZtcKdL/dKfbDSMMAiKR8FKBrNqw6gOPFb7EPCO9rvv4nQ6oDWKfXm+sDaf1fL4VXD8B83j7AJPW5dI+L9/M25hJ90vq3awBwVdYHDBHuiYO0lJOW1xItk/IgKn8IS+tfKwCHpA8wK2zLc9JvJqUP0vrXCsAq6QOljTZhTujDbGq7CgCgQv1rBWCW9IEcAJF4+C6b6D3VE2guY0EkfVB++8itzlsInzJ/hNhdW82bp/clXMZECGCSBh0CACaxQP9HuFTjRhJGxfA6BeB4YLjYY0UAxKa/6h0AkdgXZYB87ZcBhhYy4I/m4Ch3DP5IDgYXMi0HGFrcA380K64ZzcLg4l4lAMfv1yihYngRgIaXajmSazmAP5qVrZmtAKA+N8Bh7rjizw6yxy0HOKy2ZqMAtGz+6RMYWsjkQ9Nd+aZiDyxHsoU1T/dAXQBn3UKdU8VnduVklk7kahNYOnU7pjfquoUuFKD71Z+mAbpeh7UDQPNJsI9vNAxgmVgFho1rB0B98/lWwwCO2V91D7ILB2B8CXA8C4G9DgBquzsIzDyvPYDaTwn8XwP0eLd0D9DjDSkD3H8yA2UI/QH0eEPwYNStDDDwaBhq2aSSBhrNYQDo4ASWlH7U/3D4k4oAS3rIYciQIUOmM3UCul/C/JAqQk8AAAAASUVORK5CYII="
              width="30" height="30" style="margin-top: 8px">

          </div>
        </CustomMarker>
      </GoogleMap>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar'
import NavBar from '@/components/admin/Navbar'
import { defineComponent } from "vue"
import { GoogleMap, Marker, CustomMarker, Polyline, MarkerCluster } from "vue3-google-map"
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from '@/server/axios'

export default defineComponent({
  components: { GoogleMap, Marker, CustomMarker, Polyline, MarkerCluster, SideBar, NavBar, VueDatePicker },
  data() {
    return {
      vehicles: [],
      oneCarSelect: '',
      routeCarSelect: {
        deviceID: '',
        date: ''
      },
      timeRangeRoute: {
        deviceID: '',
        date: '',
        startTime: null,
        finishTime: null
      },
      isSuccess: false,
      errorTextRoute: null,
      errorTimeRangeRoute: null,
      errorTextLive: null,
      gps: [],
      center: { lat: 39.9032224, lng: 32.4509489 },
      plate: '',
      marker: false,
      polyline: false,
      multiMarker: false,
      flightPlanCoordinates: [],
      flightPath: [],
      startMarker: [],
      finishMarker: [],
      locations: [],
      multiLine: [],
      time: null
    }
  },
  setup() {
    const key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    const mapID = process.env.VUE_APP_GOOGLE_MAPS_ID
    return { key, mapID }
  },
  mounted() {
    this.getVehicles()
  },
  methods: {
    async getVehicles() {
      await axios.get('get-vehicle', { headers: { 'X-Requested-With': 'mapVehicle' } })
        .then(response => {
          if (response.status === 200) {
            this.vehicles = response.data.data
          }
        })
        .catch(error => {
          alert(error.message)
        });
    },
    live() {
      this.errorTextRoute = null
      this.polyline = false
      this.multiMarker = false
      this.marker = false
      if (this.oneCarSelect != 0) {
        axios.post('getOneVehicleLocation', this.oneCarSelect)
          .then(response => {
            if (response.status === 200) {
              this.errorTextLive = null
              const location = response.data.data
              this.marker = true
              this.center = { lat: parseFloat(location.konumEnlem), lng: parseFloat(location.konumBoylam) }
              this.plate = this.oneCarSelect.plaka
              this.time = 'Zaman' + location.gercekSaat
            }
            if (response.status === 204) {
              this.marker = false
              this.errorTextLive = 'Konum Bilgisi Bulunamadı'
            }
            if (response.status === 203) {
              this.marker = false
              this.errorTextLive = response.data.message
            }
          })
          .catch(error => {
            this.marker = false
            console.log(error)
            this.errorTextLive = error.message
          })
      }
      if (this.oneCarSelect === '0') {
        axios.post('getAllVehiclesLocation', this.vehicles)
          .then(response => {
            if (response.status === 200) {
              this.multiMarker = true
              this.errorTextLive = null
              this.multiLine = response.data.data
              this.locations = response.data.data.map((coordinate) => ({
                lat: parseFloat(coordinate.lat),
                lng: parseFloat(coordinate.lng)
              }))
            }
            if (response.status === 204) {
              this.marker = false
              this.errorTextLive = 'Konum Bilgisi Bulunamadı'
            }
            if (response.status === 203) {
              this.marker = false
              this.errorTextLive = response.data.message
            }
          })
          .catch(error => {
            this.marker = false
            this.errorTextLive = error.message
          })
      }
    },
    route() {
      this.errorTextLive = null
      this.marker = false
      this.multiMarker = false
      axios.post('createRoute', this.routeCarSelect)
        .then(response => {
          if (response.status === 200) {
            this.errorTextRoute = null
            this.polyline = true
            this.flightPlanCoordinates = response.data.data.map((coordinate) => ({
              lat: parseFloat(coordinate.konumEnlem),
              lng: parseFloat(coordinate.konumBoylam)
            }))
            this.center = this.flightPlanCoordinates[0]
            this.startMarker = {
              position: this.flightPlanCoordinates[0],
              title: 'Başlangıç Noktası Saat: ' + response.data.data[0].gercekSaat,
              icon: require('@/assets/images/placeholder.png')
            }
            this.finishMarker = {
              position: this.flightPlanCoordinates[this.flightPlanCoordinates.length - 1],
              title: 'Bitiş Noktası Saat: ' + response.data.data[response.data.data.length - 1].gercekSaat
            }
            this.flightPath = {
              path: this.flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#5D87FF",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }
          if (response.status === 203) {
            this.polyline = false
            this.errorTextRoute = response.data.message
          }
          if (response.status === 204) {
            this.polyline = false
            this.errorTextRoute = 'Konum Bilgisi Bulunamadı'
          }
        })
        .catch(error => {
          this.errorTextRoute = error.message
        })
    },
    timeRangeRouteMethod() {
      this.errorTextLive = null
      this.marker = false
      this.multiMarker = false
      if (this.timeRangeRoute.startTime.hours < 10) {
        this.timeRangeRoute.startTime.hours = '0' + this.timeRangeRoute.startTime.hours
      }
      if (this.timeRangeRoute.startTime.minutes < 10) {
        this.timeRangeRoute.startTime.minutes = '0' + this.timeRangeRoute.startTime.minutes
      }
      if (this.timeRangeRoute.finishTime.hours < 10) {
        this.timeRangeRoute.finishTime.hours = '0' + this.timeRangeRoute.finishTime.hours
      }
      if (this.timeRangeRoute.finishTime.minutes < 10) {
        this.timeRangeRoute.finishTime.minutes = '0' + this.timeRangeRoute.finishTime.minutes
      }
      this.timeRangeRoute.startTime = this.timeRangeRoute.startTime.hours + ':' + this.timeRangeRoute.startTime.minutes + ':00'
      this.timeRangeRoute.finishTime = this.timeRangeRoute.finishTime.hours + ':' + this.timeRangeRoute.finishTime.minutes + ':00'
      axios.post('timeRangeRoute', this.timeRangeRoute)
        .then(response => {
          if (response.status === 200) {
            errorTimeRangeRoute = null
            this.errorTextRoute = null
            this.polyline = true
            this.flightPlanCoordinates = response.data.data.map((coordinate) => ({
              lat: parseFloat(coordinate.konumEnlem),
              lng: parseFloat(coordinate.konumBoylam)
            }))
            this.center = this.flightPlanCoordinates[0]
            this.startMarker = {
              position: this.flightPlanCoordinates[0],
              title: 'Başlangıç Noktası Saat: ' + response.data.data[0].gercekSaat,
              icon: require('@/assets/images/placeholder.png')
            }
            this.finishMarker = {
              position: this.flightPlanCoordinates[this.flightPlanCoordinates.length - 1],
              title: 'Bitiş Noktası Saat: ' + response.data.data[response.data.data.length - 1].gercekSaat
            }
            this.flightPath = {
              path: this.flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#5D87FF",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }
          if (response.status === 203) {
            this.polyline = false
            this.errorTimeRangeRoute = response.data.message
          }
          if (response.status === 204) {
            this.polyline = false
            this.errorTimeRangeRoute = 'Konum Bilgisi Bulunamadı'
          }
        })
        .catch(error => {
          this.errorTimeRangeRoute = error.message
        })
      this.timeRangeRoute.date = null
      this.timeRangeRoute.startTime = null
      this.timeRangeRoute.finishTime = null
    }
  }
});
</script>
<style scoped>
#map-blog {
  right: 0;
  position: fixed;
  height: calc(100% - 71px);
  bottom: 0;
}

#map-menu {
  background-color: #F5F6F9;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 5%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
}

#map {
  position: absolute;
  height: 100%;
  right: 0;
}

@media (min-width: 1200px) {
  #map-blog {
    width: calc(100% - 270px);
    left: 270px;
  }
}

@media (max-width: 1199px) {
  #map-blog {
    width: 100%;
  }
}

@media (min-width: 992px) {
  #map-menu {
    width: 35%;
  }

  #map {
    width: 65%;
  }
}

@media (max-width: 992px) {

  #map-menu {
    width: 45%;
  }

  #map {
    width: 55%
  }
}

@media (max-width: 768px) {
  #map-menu {
    width: 60%;
  }

  #map {
    width: 40%;
  }
}
</style>