<template>
    <div class="taxesform">
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <SideBar />
            <NavBar />
            <br><br>
            <div class="App">
                <div class="container">
                    <h2>Yakıt İşlemleri</h2>
                    <form>
                        <div class="form-group">
                            <label for="vehicle">Araç Seç:</label>
                            <select v-model="selectedVehicle">
                                <option value="">Araç Seçiniz</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="information">Yakıt
                                Bilgileri</button>
                            <button type="button" class="btn btn-warning" @click="reportBtn">Rapor</button>
                            <button type="button" class="btn btn-info" @click="add">Yakıt Bilgisi Ekle</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal taxe -->
        <div class="modal fade" id="fuelModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Yakıt Bilgileri</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <DataTable :data="fuel" :columns="columns" class="table table-striped table-bordered display"
                            id="fuelDatatable" :options="dataTableOptionsWithButtons">
                        </DataTable>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="fuelEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Bilgileri Düzenle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="editedFuel.id">
                            <div class="mb-3">
                                <label for="fuel" class="form-label">Yakıt Miktarı (litre)</label>
                                <input class="form-control" type="text" v-model="editedFuel.liter" required>
                            </div>
                            <div class="mb-3">
                                <label for="fuel" class="form-label">Tutar</label>
                                <input class="form-control" type="text" v-model="editedFuel.price" required>
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Yakıt Alınan KM</label>
                                <input class="form-control" type="text" v-model="editedFuel.km" required>
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">Yakıt Alınan Tarih</label>
                                <VueDatePicker v-model="editedFuel.date" :enable-time-picker="false" :format="format"
                                    required />
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Değişiklikleri Kaydet</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="fuelAddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Yakıt Bilgisi Ekle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="addButton" v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="fuel" class="form-label">Yakıt Miktarı (litre)</label>
                                <input class="form-control" type="text" v-model="addFuel.liter" required>
                            </div>
                            <div class="mb-3">
                                <label for="fuel" class="form-label">Tutar</label>
                                <input class="form-control" type="text" v-model="addFuel.price" required>
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Yakıt Alınan KM</label>
                                <input class="form-control" type="text" v-model="addFuel.km" required>
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">Yakıt Alınan Tarih</label>
                                <VueDatePicker v-model="addFuel.date" :enable-time-picker="false" :format="format"
                                    required />
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Bilgileri Ekle</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Report -->
        <div class="modal fade" id="fuelReportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Rapor</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="toReport" v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="startDate" class="form-label">Başlangıç Tarihi</label>
                                <VueDatePicker v-model="report.startDate" :max-date="report.finishDate"
                                    :enable-time-picker="false" :format="format" required />
                            </div>
                            <div class="mb-3">
                                <label for="finishDate" class="form-label">Bitiş Tarihi</label>
                                <VueDatePicker v-model="report.finishDate" :min-date="report.startDate"
                                    :enable-time-picker="false" :format="format" required />
                            </div>
                            <a id="changesText" style="color:red;">{{ this.toReportText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Rapor Oluştur</button>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
        <!-- Modal To Report -->
        <div class="modal fade" id="fuelToReportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ this.startDate }} / {{
                            this.finishDate }} tarihleri için yakıt tüketim raporu</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="total" class="form-label">Toplam Yakıt</label>
                                <input type="text" v-model="report.liter" readonly>
                            </div>
                            <div class="mb-3">
                                <label for="price" class="form-label">Toplam Tutar</label>
                                <input type="text" v-model="report.price" readonly>
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Km</label>
                                <input type="text" v-model="report.km" readonly>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            fuel: [],
            editedFuel: [],
            addFuel: [],
            report: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            format: null,
            isSuccess: true,
            dateInput: null,
            finishDate: null,
            toReportText: null,
            startDate: null,
            finishDate: null,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'ID' },
                { data: 'plate', title: 'Araç Plakası' },
                { data: 'date', title: 'Yakıt Alınan Tarihi' },
                { data: 'km', title: 'Yakıt Alınan Kilometre' },
                { data: 'price', title: 'Tutar' },
                { data: 'liter', title: 'Miktar (litre)' },
                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                // https://datatables.net/reference/option/dom
                // l - length changing input control
                // f - filtering input
                // t - The table!
                // i - Table information summary
                // p - pagination control
                // r - processing display element
                //dom: 'B<"row"<"col-md-3"l><"col-md-3"f><"col-md-2">rtip',
                dom: 'B<"row"<"col-md-3"l><"col-md-6"><"col-md-3"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],
                lengthMenu: [10, 25, 50, 100],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',
                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#fuelDatatable').on('click', '.edit-button', function () {
            const fuelID = $(this).data('id')
            const ins = self.fuel.find(ins => ins.id === fuelID)
            if (ins) {
                self.editedFuel.id = ins.id
                self.editedFuel.date = ins.date
                self.editedFuel.carID = ins.carID
                self.editedFuel.km = ins.km
                self.editedFuel.price = ins.price
                self.editedFuel.liter = ins.liter
                self.saveChangesText = null
                $('#fuelEditModal').modal('show')
            }
        })
        $('#fuelDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.delete(insID);
        })


    },
    watch: {
        /*
        startDate(newValue, oldValue) {
            const startDate = new Date(this.startDate);
            const finishDate = new Date(newValue);
            console.log(newValue)
            if (finishDate < startDate) {
                alert('Bitiş tarihi, başlangıç tarihinden önce olamaz. Lütfen tekrar seçin.');
                this.finishDate = '';
            }
        }
        */
    },
    methods: {

        async getVehicles() {
            await axios.get('get-vehicle', { headers: { 'X-Requested-With': 'maintenanceVehicle' } })
                .then(response => {
                    if (response.status === 200) {
                        this.vehicles = response.data.data;
                    } else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message);
                });
        },
        async information() {
            await axios.post('information-fuel', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.fuel = response.data.data
                        $('#fuelModal').modal('show')
                    }
                    else if (response.status === 203) {
                        this.formText = response.data.message
                        $('#fuelModal').modal('hide')
                    }
                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {
            const editedFuelCopy = { ...this.editedFuel }
            await axios.post('update-fuel', editedFuelCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                        this.information()
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async delete(insID) {
            await axios.delete(`delete-fuel/${insID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.information()
                    } else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message)
                })
        },
        add() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#fuelAddModal').modal('show')
            }
            else {
                this.formText = "Araç Seçiniz!"
            }

        },
        async addButton() {
            const addFuelCopy = { ...this.addFuel }
            addFuelCopy.carID = this.selectedVehicle.id
            await axios.post('add-fuel', addFuelCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                    }
                    else if (response.status === 203){
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        reportBtn() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#fuelReportModal').modal('show')
            }
            else {
                this.formText = "Araç Seçiniz!"
            }
        },
        async toReport() {
            if (this.report.startDate && this.report.finishDate) {
                this.formText = null
                this.saveChangesText = null
                this.startDate = this.formatDate(this.report.startDate)
                this.finishDate = this.formatDate(this.report.finishDate)
                await axios.post('information-fuel', this.selectedVehicle)
                    .then(response => {
                        if (response.status === 200) {
                            this.formText = null
                            this.fuel = response.data.data
                            let totalLiter = 0
                            let totalPrice = 0
                            let maxKm = 0
                            let minKm = Infinity
                            for (const data of this.fuel) {
                                if (data.date >= this.startDate && data.date <= this.finishDate) {
                                    totalLiter += data.liter
                                    totalPrice += data.price
                                    if (maxKm < data.km) {
                                        maxKm = data.km
                                    }
                                    if (minKm > data.km) {
                                        minKm = data.km
                                    }
                                }
                            }
                            this.report.km = maxKm - minKm
                            this.report.price = totalPrice
                            this.report.liter = totalLiter
                            $('#fuelToReportModal').modal('show')
                        }
                        else if (response.status === 203){
                            this.toReportText = response.data.message
                        }
                    })
                    .catch(error => {
                        this.toReportText = error.message
                    });
            }
            else {
                this.toReportText = "Tarih aralığı seçin!"
            }
        },
    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   