<template>
    <div class="shubyform">
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <SideBar />
            <NavBar />
            <br><br>
            <div class="App">
                <div class="container">
                    <h2>SHUBY</h2>
                    <form @submit.prevent="surusDegerlendirme">
                        <div class="form-group">
                            <label for="vehicle">Araç Seç:</label>
                            <select v-model="vehicleInformation" required>
                                <option value="">Select Vehicle</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka
                                }}</option>
                            </select>
                        </div>
                        <button type="submit" >Sürüş Değerlendirme Sonuçları</button>
                        <div class="form-group">
                            <a id="errorText">{{ this.errorText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="shubyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Sürüş Detayları</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <DataTable :data="shuby" :columns="columns" class="table table-striped table-bordered display"
                            :options="dataTableOptions">
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3';
import DataTableLib from 'datatables.net-bs5';
import Buttons from 'datatables.net-buttons-bs5';
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5';
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5';
DataTable.use(DataTableLength);


DataTable.use(DataTableLib);
DataTable.use(ButtonsHtml5);
DataTable.use(Buttons);
export default {
    components: {
        SideBar,
        NavBar,
        DataTable,
    },
    data() {
        return {
            vehicles: [],
            vehicleInformation: '',
            editedVehicle: {},
            errorText: '',
            successText: '',
            shuby: [],

            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'id' },
                { data: 'deviceID', title: 'Araç ID' },
                { data: 'plaka', title: 'Araç Plakası' },
                { data: 'cokVerimli', title: 'Çok Verimli(%)' },
                { data: 'verimli', title: 'Verimli(%)' },
                { data: 'azVerimli', title: 'Az Verimli(%)' },
                { data: 'verimsiz', title: 'Verimsiz(%)' },
                { data: 'cokVerimsiz', title: 'Çok Verimsiz(%)' },
                { data: 'startTime', title: 'Başlangıç Tarihi' },
                { data: 'finishTime', title: 'Bitiş Tarihi' }
            ],
            dataTableOptions: {
                responsive: true,
                autoWidth: false,
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],
                lengthMenu: [10, 25, 50, 100], //
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',
                },
            },
        }
    },
    mounted() {
        this.getVehicles()
    },
    methods: {
        getVehicles() {
            axios.get('get-vehicle', { headers: { 'X-Requested-With': 'shubyVehicle' } })
                .then(response => {
                    if (response.status === 200)
                        this.vehicles = response.data.data
                })
                .catch(error => {
                    console.log(error.message)
                });
        },
        surusDegerlendirme() {
            axios.get(`shubyData/${this.vehicleInformation.deviceID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.errorText = ''
                        this.shuby = response.data.data
                        $('#shubyModal').modal('show')
                    } else if (response.status === 203) {
                        this.errorText = response.data.message
                    } else if (response.status === 204) {
                        this.errorText = 'Kayıt bulunamadı!'
                    }
                })
                .catch(error => {
                    this.errorText = error.messages
                });

        },
    }
}
</script>
<style scoped>
#errorText {
    color: red;
}

#successText {
    color: green;
}

#shubyModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
    /* Adjust this value as needed */
}
</style>
  