<template>
  <!--  Body Wrapper -->
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <!-- Sidebar Start -->
    <SideBar />
    <NavBar />
    <br><br><br><br>
    <!--  Sidebar End -->
    <!--  Main wrapper -->
    <div class="body-wrapper">
      <!--  Header Start -->
      <div class="RegisterPage">
        <!--  Header End -->
        <div class="container">
          <h2>Müşteri Ekle</h2>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="ad">Ad</label>
                  <input class="form-control" v-model="user.name" type="text" id="ad" name="ad" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="soyad">Soyad</label>
                  <input v-model="user.lastName" type="text" id="soyad" name="soyad" required>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="sabitNumara">Sabit Numara</label>
                  <input v-model="user.sabitNumara" type="tel" id="sabitNumara" name="sabitNumara" required>
                </div>
              </div>
              <div class="col-md-6">
                <label for="cepTelefonu">Cep Telefonu</label>
                <MazPhoneNumberInput v-model="phoneNumber" show-code-on-list color="info"
                  :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB', 'TR']" :ignored-countries="['AC']"
                  @update="results = $event" :success="results && isValid" />
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="email">E-Mail</label>
              <input v-model="user.email" type="email" id="email" name="email" required>
            </div>
            <br>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="sirketUnvani">Şirket Ünvanı</label>
                  <input v-model="user.sirketUnvani" type="text" id="sirketUnvani" name="sirketUnvani" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vergiDairesi">Vergi Dairesi</label>
                  <input v-model="user.vergiDairesi" type="text" id="vergiDairesi" name="vergiDairesi" required>
                </div>
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="vergiNumarasi">Vergi Numarası</label>
              <input v-model="user.vergiNumarasi" type="text" id="vergiNumarasi" name="vergiNumarasi" required>
            </div>
            <br>
            <div class="form-group">
              <label for="adres">Adres</label>
              <input v-model="user.adres" type="text" id="adres" name="adres" required>
            </div>
            <br>
            <div class="row form-group">
              <div class="col-md-4">
                <label>Ülke</label>
                <select class="form-select" v-model="selectedCountry" @change="getStates" required>
                  <option value="">Select Country</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="state">Bölge/Şehir</label>
                <select class="form-select" v-model="selectedState" @change="getCities" required>
                  <option value="">Select State</option>
                  <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label>İlçe</label>
                <select class="form-select" v-model="selectedCity">
                  <option value="">Select City</option>
                  <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
                </select>
              </div>
            </div>
            <br>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6"><label for="postaKodu">Müşteri Tipi</label>
                  <select class="form-select" v-model="user.auth" required>
                    <option value="">Select Auth</option>
                    <option value="2">Client</option>
                    <option value="3">Subclient</option>
                  </select>
                </div>
                <div class="col-md-6"><label for="auth">Posta Kodu</label>
                  <input v-model="user.postaKodu" type="postaKodu" id="postaKodu" name="postaKodu" required>
                </div>
              </div>

            </div>
            <br>
            <div class="form-group" v-if="user.auth == 3">
              <div class="row">
                <div class="col-md-12">
                  <label for="user">Kullanıcı Seç:</label>
                  <select class="form-select" v-model="user.userID" required>
                    <option value="">Select User</option>
                    <option v-for="user in users" :key="user.id" :value="user.userID">{{ user.email }}</option>
                  </select>
                </div>
              </div>
            </div>
            <br>
            <div class="form-group">
              <label for="password">Şifre</label>
              <input v-model="user.password" type="password" id="password" name="password" required>
            </div>
            <br>
            <div class="form-group">
              <label for="confirmPassword">Şifre Tekrar</label>
              <input v-model="user.confirmPassword" type="password" id="confirmPassword" name="confirmPassword" required>
            </div>
            <br>
            <div class="form-group">
              <button type="submit">Müşteri Oluştur</button>
            </div>
            <a id="errorText">{{ this.errorText }}</a>
            <a id="successText">{{ this.successText }}</a>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
  components: {
    SideBar,
    NavBar,
    MazPhoneNumberInput
  },
  data() {
    return {
      countries: [],
      states: [],
      cities: [],
      selectedCountry: '',
      selectedState: '',
      selectedCity: '',
      phoneNumber: null,
      results: false,
      isValid: false,
      user: {
        name: '',
        lastName: '',
        sabitNumara: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        sirketUnvani: '',
        vergiDairesi: '',
        vergiNumarasi: '',
        adres: '',
        city: '',
        country: '',
        state: '',
        postaKodu: '',
        auth: ''
      },
      passwordVisible: false,
      errorText: '',
      successText: ''

    };
  },
  mounted() {
    this.getUsers()
    this.getCountries()
  },
  methods: {
    getUsers() {
      axios.get('usersInformation', {headers:{'X-Requested-With': 'createUser'}})
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    getCountries() {
      axios.get('countries')
        .then(response => {
          this.countries = response.data;
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    getStates() {
      axios.get('states/' + this.selectedCountry)
        .then(response => {
          this.states = response.data;
          this.selectedState = '';
          this.cities = [];
          this.selectedCity = '';
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    getCities() {
      axios.get('cities/' + this.selectedState)
        .then(response => {
          this.cities = response.data;
          this.selectedCity = '';
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    submitForm() {
      this.errorText = ''
      this.successText = ''
      if (this.results.isValid === false) {
        this.errorText = 'Telefon formatını kontrol ediniz!'
      } else {
        this.user.phoneNumber = this.phoneNumber
        this.user.country = this.selectedCountry
        this.user.state = this.selectedState
        this.user.city = this.selectedCity
        axios.post('addCustomer', this.user)
          .then(
            response => {
              this.submitValue = 'Lütfen Bekleyiniz..'
              try {
                if (response.status === 200) {
                  this.errorText = ''
                  this.successText = 'Kayıt Oluşturuldu'
                } if (response.status === 203) {
                  this.errorText = response.data.message
                }
              } catch (err) {
                this.errorText = err.message
              }
            }
          )
          .catch(error => {
            this.errorText = error.message;
          });
      }

    }
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}

label {
  font-weight: bold;
}
</style>