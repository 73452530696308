<template>
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div>
      <div class="brand-logo d-flex align-items-center justify-content-between">
        <a href="/admin" class="text-nowrap logo-img">
          <img src="images/logo3.png" width="180" alt="" />
        </a>
        <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse"><i data-v-629e66b0="" class="ti ti-x fs-8"></i></div>

      </div>
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
        <ul id="sidebarnav">
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu user-name">Welcome {{ name }}</span>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Home</span>
          </li>
          <li class="sidebar-item">
            <router-link to="/admin" class="sidebar-link" aria-expanded="false">
              <span>
                <i class="ti ti-layout-dashboard"></i>
              </span>
              <span class="hide-menu">Ana Sayfa</span>
            </router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Ekleme İşlemlerİ</span>
          </li>
          <li class="sidebar-item">
            <router-link to="/addCustomer" class="sidebar-link" aria-expanded="false">
              <span>
                <i class="ti ti-id-badge"></i>
              </span>
              <span class="hide-menu">Müşteri Oluştur</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/arackayit" class="sidebar-link" aria-expanded="false"><span>
                <i class="ti ti-dashboard"></i>
              </span>
              <span class="hide-menu">Araç Ekle</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/driverCreate" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHElEQVR4nNXUTyuEURTH8Q9SNpSYhRVZUqTZsLOwt6BIYcdCyb+yQETMQlNeAAsLC2/Aft6ZTp2pJx7TPKOUb50693fOPc+9zz338t+ZwCVu0mZ+U2wd1xjO8QDOcYHRqsV2sVmiT2IMD1ULbmAVg+nHSu9whmZuvzK3eMIC+gr6Ft56KfiBtRJ9Ga1eCr5iCnsFbS+1iFXmAvUSvZ6xytRwVKIfY1yPHGGuMJ7/4SNd058HcJ/WSq0yQ9hGA8+5/Vr6jYxFTlesZPPOFu7zQVr4MtbM3I4sfWmTxVzRS1ojtTb7X8bfuCr8o3hZTgrNHCa1OJz2g3HVqeAODrN54zQf8x6/p4Uf2mnmRG7M6YppjKQfD0VYEFrE/oZPl1Apc/Qgz7sAAAAASUVORK5CYII=">
              </span>
              <span class="hide-menu">Şoför Oluştur</span></router-link>
          </li>
          <!--
          <li class="sidebar-item">
            <router-link to="/assignform" class="sidebar-link" aria-expanded="false"><span>
                <i class="ti ti-cards"></i>
              </span>
              <span class="hide-menu">Araç Tanımla</span></router-link>
          </li>-->
          <li class="sidebar-item">
            <router-link to="/driverAssing" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAvUlEQVR4nO3SsWoCQRSF4a+xV9OLprQKsbBJaZNHkDQRyzSBiBaCGiubJI2ktPUxfDWbKyzL7o7FYuWBw9zhDv/hzgx31aU5dhX+DVedmWWBm0RgM1ylTdlmiP9ILQL2scUCn9cAP/CEfQnwB42oJ3hOAd8DdkC7ALhGN+oeVingW6wPeC0AnvAXV3LENAWs9VEeMcq5lQPm+4ObAl/iO1zcKRg52x+nRq7tDmdY5tKz/g6X9Zf4SgTe5TqdAakIMWinn6t7AAAAAElFTkSuQmCC">
              </span>
              <span class="hide-menu">Şoför Atama</span></router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Görüntüleme</span>
          </li>
          <li class="sidebar-item">
            <router-link to="/users" class="sidebar-link" aria-expanded="false"><span>
                <i class="ti ti-user"></i>
              </span>
              <span class="hide-menu">Kullanıcılar</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/vehicles" class="sidebar-link" aria-expanded="false"><span>
                <i class="ti ti-car"></i>
              </span>
              <span class="hide-menu">Araçlar</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/driver" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHElEQVR4nNXUTyuEURTH8Q9SNpSYhRVZUqTZsLOwt6BIYcdCyb+yQETMQlNeAAsLC2/Aft6ZTp2pJx7TPKOUb50693fOPc+9zz338t+ZwCVu0mZ+U2wd1xjO8QDOcYHRqsV2sVmiT2IMD1ULbmAVg+nHSu9whmZuvzK3eMIC+gr6Ft56KfiBtRJ9Ga1eCr5iCnsFbS+1iFXmAvUSvZ6xytRwVKIfY1yPHGGuMJ7/4SNd058HcJ/WSq0yQ9hGA8+5/Vr6jYxFTlesZPPOFu7zQVr4MtbM3I4sfWmTxVzRS1ojtTb7X8bfuCr8o3hZTgrNHCa1OJz2g3HVqeAODrN54zQf8x6/p4Uf2mnmRG7M6YppjKQfD0VYEFrE/oZPl1Apc/Qgz7sAAAAASUVORK5CYII=">
              </span>
              <span class="hide-menu">Şoförler</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/vehiclesAndUsers" class="sidebar-link" aria-expanded="false"><span>
                <i class="ti ti-files"></i>
              </span>
              <span class="hide-menu">Kullanıcılar && Araçlar</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/driverAndCar" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwElEQVR4nOVTPYsiQRTsCfwHy8g4iWNodv4Gf4DJGAgaiBgbKGogCJsYmKnIhhoYiEaGGggGcgeeqcI4mCh3IKgg4tday3uss+t+3bKXHNyDSqq7q1/V6xbi/yibzfZNVdWhw+GYfxWqqv4kHaEoym/TNEF1Pp8xGAxwOp1wqUqlgt1uh4/KNE0oivJLOJ3O+wtZq9UghEA+n7c2rlYrfKY0TbsnMYuYTCbQdR3D4dDiyuUy9vs9xuMx2u32FTqdjtW1pmmwxI7HI1KpFFwuF6LRKLbbLfN06HA4oNlsIplMXiGdTmOxWLwWi8fjbPGCQCDAPHVEWRaLRe5af0QwGGT+mc0nsc1mw/a63S4PYb1eM18qldjKbDbjte4j+v0+ptPp22Ik4Ha7+VaPx4NWq8X8aDTiDkj0eWe6rsPv92M+n78Wy2QyKBQKPIR6vY5wOMw8hUyZNRqNq7yy2ez7NmOxGN9GeXm9XkQiEWuaZPPlNHu93sun8SSWSCTg8/mQy+U4/FAoxPxyufzsO4Ow2+0wDOPdTdVq9Y8/wDAMkI6QJOmHLMugDr8KWZYhSdJ3+us3QohbIcTdX4DOk84/Wg9uYMNZI89b5QAAAABJRU5ErkJggg==">
              </span>
              <span class="hide-menu">Şoförler && Araçlar</span></router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">SHUBY</span>
          </li>
          <li class="sidebar-item">
            <router-link to="/shuby" class="sidebar-link" aria-expanded="false"><span>
                <i class="ti ti-aperture"></i>
              </span>
              <span class="hide-menu">Shuby</span></router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">VERGİ - KASKO - SİGORTA</span>
          </li>
          <li class="sidebar-item">
            <router-link to="/taxes" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4UlEQVR4nLXUsUpDQRBG4Y80NnkHsdLGMthqZWUgqJBCCNhY2Cv4BBbmCSI2VhZ5grSptRGtLRNDrpW1BK5wWUNg7sYDC7tbHGbY2Z9/ZAuXuA6uKxyksl184BZjvAaEN3jDaVV4h4ty38Q02F0Xg+rFPY4r5yIo7OChjrCP1rqEZ3hJW8sRnuMZJ+sSNnCEz/LhsoU7mGFUp8IvyznEZh1hIUZnmfCxMv3fucK95Dv9CtuYr1iTMgP+CFPmuRWmFCsqXIzNdlQ4w4aMcJDwhGEgvt7T+EpZVNcLBOx+oJsYP1hPV7kyn4fBAAAAAElFTkSuQmCC">
              </span>
              <span class="hide-menu">Vergi İşlemlerİ</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/insurance" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABa0lEQVR4nKXUzUtVQRjH8Q/YC7cIDTdiCylXCS5cmYSrjFaBuYj8B0JahUsRFVy6F3IhWOlKF5LUpjbKTS0rUHTdnyIDz5XDYc71ev3CD54z87zMPGdmaI2+0LV5jl18D+3G2JWoYRJ1rKK/MNcfY/XwqVUluYUp7OAn5tDTpGhP+NTxJWJTjgu6cdJmn/oitru8zV8VAQ/wJtRb4fMbt8uDxxnH99jHfCgVncn4neSq/C31YQLruIHHoWRv4FXB7w4OcwnX8LTw/Q9dYb8NJe5H8QZjWM4lTAGLmW0c4X/oKLPFJbyuOgrFpZ/hZmaFqS2npR9yTwXbGAp7Ln5EYjSUWMBs2M+wognD2Ao7re4TPuJlKNmbhZV/xUOX8A1PSk1fCr0ojKcCH7RAqvinWV/iVqQr2qlFJmPrHZm5Wrw8xSPWEtP4XDrsd+MxSIe+Ld7hBx5hAHsYbzdZgxEcRM8Gr5usQbrDSZdyDl9UQKfF3xV4AAAAAElFTkSuQmCC">
              </span>
              <span class="hide-menu">Sigorta İşlemlerİ</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/kasko" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgElEQVR4nMXTsUtCQRwH8GupLWouCEKoaOkPCB7e6e8ODaTUpZoKQgxB+gci2oQGazP17j0TRWgSaWg1CaqxaGkwgqi2amipi1c8RPPUp0JfuOV4v8/de7/fQ+g/onFthGXYcF8wl+6aIQKuMacXWHjHuoZYnA0RnW4RTt+IoPJ3wSPRqc8WAhymMYco5rRag+oX5lDBnK2TFJkMFoKDShALmlUhSlzQIyVIOBx2ASbUoKA7rYpdgslQcaMBhO1WN1xphSUu4/LmpSwD+WBtPw3LSlAzwGE9CIZH7p/H5ELWV4dFSpt1B2kGOJQgkmjA6u5izi8r9yV5encsU1cHTTHC4cGsUYM/nYaYVbCU88tytShvn89k9CTSbHz2ULs4BZ3FAj6tIn8+IMPF0N/vyumXm7vnUCchAoz2IwOFjjAzzqRnAnP6rp49+HAm6RSyEyzYqvlaTVGdhW1hVsx/uhHFHHZRLyE69WFOn4iAV5KGtZ4wK/NZ76iWYeN9wezmGw/panxtduniAAAAAElFTkSuQmCC">
              </span>
              <span class="hide-menu">Kasko İşlemleri</span></router-link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">BAKIM - LASTİK - YAKIT</span>
          </li>
          <li class="sidebar-item">
            <router-link to="/maintenance" class="sidebar-link" aria-expanded="false"><span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGklEQVR4nM3UzyqEYRTH8Y8F4yYoNQtRLsHG0kLINbDiApSVK1AahRTKYmxoSgYLayWugcxKFv5ulJ46aprkfWbMwm/1vOd93u97zu885yFfPZjDGZ7whlss6EB9qOId65jBIj6x1wmwgnuMxHMZd6ih1C5sNDIZb4rVcd4EK2Ef8znAFVy1xA4j629YLX56I0O72G6JraIR0IsoP3n6mgPcwkFLbCCg1Sg/eTqLxxzgUmRTpA2cFm0qR3ePC/aN4QPTRbCcozGBhx9sKYSVItNGfLyD6+juGnrbhdWi/NTNzej+MoZzMjtBf9PYHUU8vc9W+d/Chn7xrG2YGPJ6t2DwjMluwZLSDXyJqVj/CZY0GCW/xCwmTzvWF/UQUNBuvvFsAAAAAElFTkSuQmCC">
              </span>
              <span class="hide-menu">Bakım</span></router-link>
          </li>
          <li class="sidebar-item">
            <router-link to="/wheel" class="sidebar-link" aria-expanded="false"><span>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsElEQVR4nJ3US29NURQH8N8YrQmzRkIoQg2Z8CGExlcQM0nr0Q6YSNQzHmHIpLM25jUQMZB4XCUmbXwAlNSYuGTLOs2yndt7YyUr2Wc9/nuv/1rr0F82YU/oRv8pmzGNN/hVabFNYXhQsBP40gJU6wrG+4GdRTcl/cQSPoQuha3xdzG53ssy2Bx2Yj8e4gEOhG2+Aj3extlKCroc9m24j7u4HeeR8F1P8Z9rTqeT8xkWI+FbuuBSnFdxB6/xNOWVRq3JYuLsOQ5hAl/xI8g/iu/4iFM4jBeJpk4DNpRuKbfuxXtcwFbsw7loWPFtiRe/w44Aarj8M6ejCXA2Lnkc3zcC6GpoOd8K30LEzqb80V6ACxXgtdAzgwAOJUMnlXwxSt4doAVsV5Q8g7fY3laytGLdaMpBnI6NaWvKSRzBy9SUwv+aTFVj04lyV8M2U43NPbzCk5R3PgMOV4N9Jewjkdw22DdT/Keg7i8Zr1ZvPkgeS6s3FrZH1eod67XPkxVoOS+nn8Nyi3+iF1gjZdHLbvb7fZWYni+rpXBaGtWMRNbSzdKAfzgbVDbELBYt53XlNzhwxOQhn8gVAAAAAElFTkSuQmCC">
              </span>
              <span class="hide-menu">Lastik</span></router-link>
          </li>

          <li class="sidebar-item">
            <router-link to="/fuel" class="sidebar-link" aria-expanded="false"><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fuel-pump"
                  viewBox="0 0 16 16">
                  <path d="M3 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-5Z" />
                  <path
                    d="M1 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v8a2 2 0 0 1 2 2v.5a.5.5 0 0 0 1 0V8h-.5a.5.5 0 0 1-.5-.5V4.375a.5.5 0 0 1 .5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 0 0-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 0 1 0-1c.564 0 1.034.11 1.412.336.383.228.634.551.794.907.295.655.294 1.465.294 2.081v3.175a.5.5 0 0 1-.5.501H15v4.5a1.5 1.5 0 0 1-3 0V12a1 1 0 0 0-1-1v4h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V2Zm9 0a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v13h8V2Z" />
                </svg>
              </span>
              <span class="hide-menu">Yakıt</span></router-link>
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</template>
<script>
import app from '@/main'
import VueJwtDecode from 'vue-jwt-decode'
export default {
  data() {
    return {
      name: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).name,
    }
  },
  mounted() {
    // Vue bileşeni sayfa yüklendiğinde çalışır
    this.autoSelectLeftNavbar();
  },
  methods: {
    autoSelectLeftNavbar() {
      "use strict";
      var url = window.location + "";
      var path = url.replace(
        window.location.protocol + "//" + window.location.host + "/",
        ""
      );
      var element = $("ul#sidebarnav a").filter(function () {
        return this.href === url || this.href === path; // || url.href.indexOf(this.href) === 0;
      });
      element.parentsUntil(".sidebar-nav").each(function (index) {
        if ($(this).is("li") && $(this).children("a").length !== 0) {
          $(this).children("a").addClass("active");
          $(this).parent("ul#sidebarnav").length === 0
            ? $(this).addClass("active")
            : $(this).addClass("selected");
        } else if (!$(this).is("ul") && $(this).children("a").length === 0) {
          $(this).addClass("selected");
        } else if ($(this).is("ul")) {
          $(this).addClass("in");
        }
      });

      element.addClass("active");
      $("#sidebarnav a").on("click", function (e) {
        if (!$(this).hasClass("active")) {
          // hide any open menus and remove all other classes
          $("ul", $(this).parents("ul:first")).removeClass("in");
          $("a", $(this).parents("ul:first")).removeClass("active");

          // open our new menu and add the open class
          $(this).next("ul").addClass("in");
          $(this).addClass("active");
        } else if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          $(this).parents("ul:first").removeClass("active");
          $(this).next("ul").removeClass("in");
        }
      });
      $("#sidebarnav >li >a.has-arrow").on("click", function (e) {
        e.preventDefault();
      });
    },
  },
}
</script>
<style scoped>
.user-name {
  font-size: 16px;
}
</style>
