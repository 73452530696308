<template>
    <div class="taxesform">
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <SideBar />
            <NavBar />
            <br><br>
            <div class="App">
                <div class="container">
                    <h2>VERGİ</h2>
                    <form>
                        <div class="form-group">
                            <label for="vehicle">Araç Seç:</label>
                            <select v-model="selectedVehicle" required>
                                <option value="">Araç Seçiniz</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="vergiBilgilendirme">Vergi
                                Bilgileri</button>
                            <button type="button" class="btn btn-info" @click="taxeAdd">Vergi Bilgisi Ekle</button>
                        </div>
                        <div class="form-group">
                            <a id="errorText">{{ this.errorText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal taxe -->
        <div class="modal fade" id="taxesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Vergi Bilgileri</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <DataTable :data="taxes" :columns="columns" class="table table-striped table-bordered display"
                            id="taxesDatatable" :options="dataTableOptionsWithButtons">
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="taxesEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Vergi Detayları</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="editedTaxes.id">
                            <div class="mb-3">
                                <label for="year" class="form-label">Vergi Yılı</label>
                                <VueDatePicker v-model="editedTaxes.year" year-picker required />
                            </div>
                            <div class="mb-3">
                                <label for="period" class="form-label">Vergi Dönemi</label>
                                <select class="form-select" id="period" v-model="editedTaxes.period" required>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="paymentDate" class="form-label">Ödeme Zamanı</label>
                                <VueDatePicker v-model="editedTaxes.paymentDate" :enable-time-picker="false"
                                    :min-date="minDate" :max-date="maxDate" :format="format" required />
                            </div>
                        </form>
                    </div>
                    <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                        <button type="submit">Değişiklikleri Kaydet</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="taxesAddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Vergi Bilgisi Ekle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="addTaxeButton" v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="year" class="form-label">Vergi Yılı</label>
                                <VueDatePicker v-model="addTaxes.year" year-picker required/>
                            </div>
                            <div class="mb-3">
                                <label for="period" class="form-label">Vergi Dönemi</label>
                                <select class="form-select" id="period" v-model="addTaxes.period" required>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="paymentDate" class="form-label">Ödeme Zamanı</label>
                                <VueDatePicker v-model="addTaxes.paymentDate" :enable-time-picker="false"
                                    :min-date="minDateAddModal" :max-date="maxDateAddModal" :format="format" required/>
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Bilgileri Ekle</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            errorText: '',
            vehicles: [],
            selectedVehicle: '',
            taxes: [],
            editedTaxes: [],
            addTaxes: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            minDate: null,
            maxDate: null,
            minDateAddModal: null,
            maxDateAddModal: null,
            format: null,
            isSuccess: true,
            oldValue: null,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'ID' },
                { data: 'plaka', title: 'Araç Plakası' },
                { data: 'year', title: 'Yıl' },
                { data: 'period', title: 'Dönem' },
                { data: 'paymentDate', title: 'Ödeme Tarihi' },
                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],


                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',

                },


            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    watch: {
        'addTaxes.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            this.addTaxes.paymentDate = null
        },
        'editedTaxes.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            if (this.oldValue !== newValue) {
                this.editedTaxes.paymentDate = null
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.minDateAddModal = computed(() => new Date(this.addTaxes.year, 0, 1))
        this.maxDateAddModal = computed(() => new Date(this.addTaxes.year, 11, 31))
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }
        $('#taxesDatatable').on('click', '.edit-button', function () {
            const taxesID = $(this).data('id')
            const taxe = self.taxes.find(taxe => taxe.id === taxesID)
            if (taxe) {
                self.editedTaxes.id = taxe.id
                self.editedTaxes.year = taxe.year
                self.editedTaxes.period = taxe.period
                self.editedTaxes.paymentDate = taxe.paymentDate
                self.editedTaxes.carID = taxe.carID
                self.minDate = computed(() => new Date(self.editedTaxes.year, 0, 1))
                self.maxDate = computed(() => new Date(self.editedTaxes.year, 11, 31))
                self.saveChangesText = null
                self.oldValue = taxe.year
                $('#taxesEditModal').modal('show')
            }
        })
        $('#taxesDatatable').on('click', '.delete-button', function () {
            const taxeID = $(this).data('id');
            self.deleteTaxe(taxeID);
        })

    },
    methods: {

        async getVehicles() {
            await axios.get('get-vehicle', { headers: { 'X-Requested-With': 'shubyVehicle' } })
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    console.log(error.message);
                });
        },
        async vergiBilgilendirme() {
            await axios.post('taxesData', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.errorText = ''
                        this.taxes = response.data.data
                        $('#taxesModal').modal('show')
                    }
                    else if (response.status === 203) {
                        this.errorText = response.data.message
                    }

                })
                .catch(error => {
                    this.errorText = error.message
                });
        },
        async saveChanges() {

            const editedTaxesCopy = { ...this.editedTaxes }
            await axios.put(`update-tax`, editedTaxesCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                        this.vergiBilgilendirme()
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async deleteTaxe(taxeID) {

            await axios.delete(`delete-taxe/${taxeID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.vergiBilgilendirme()
                    } else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        taxeAdd() {
            if (this.selectedVehicle.deviceID) {
                this.errorText = null
                this.saveChangesText = null
                $('#taxesAddModal').modal('show')
            }
            else {
                this.errorText = "Araç Seçiniz!"
            }

        },
        async addTaxeButton() {
            this.addTaxes.carID = this.selectedVehicle.id
            const addTaxesCopy = { ...this.addTaxes }
            await axios.post('add-taxe', addTaxesCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        }

    }
}
</script>
  
<style scoped>
#errorText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}
</style>
   