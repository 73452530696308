<template>
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
        <div class="users">
            <SideBar />
            <NavBar />
            <br><br><br><br>
            <div class="body-wrapper">
                <div class="container">
                    <DataTable :data="vehicles" :columns="columns" class="table table-striped table-bordered display"
                        id="vehicleDatatable" :options="dataTableOptionsWithButtons">
                    </DataTable>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="vehicleModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Araç Detayları</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="editedVehicle">
                            <div class="mb-3">
                                <label for="chassisNumber" class="form-label">Şasi Numarası</label>
                                <input type="text" class="form-control" id="chassisNumber"
                                    v-model="editedVehicle.chassisNumber" required>
                            </div>
                            <div class="mb-3">
                                <label for="plaka" class="form-label">Araç Plakası</label>
                                <input type="text" class="form-control" id="plaka" v-model="editedVehicle.plaka" required>
                            </div>
                            <div class="mb-3">
                                <label for="deviceID" class="form-label">Araç ID</label>
                                <input type="text" class="form-control" id="deviceID" v-model="editedVehicle.deviceID"
                                    required>
                            </div>
                            <div class="mb-3">
                                <label for="marka" class="form-label">Araç Markası</label>
                                <input type="text" class="form-control" id="marka" v-model="editedVehicle.marka">
                            </div>
                            <div class="mb-3">
                                <label for="model" class="form-label">Araç Modeli</label>
                                <input type="text" class="form-control" id="model" v-model="editedVehicle.model">
                            </div>
                            <div class="mb-3">
                                <label for="muayeneZamani" class="form-label">Muayene Zamanı</label>
                                <input type="datetime-local" class="form-control" id="muayeneZamani"
                                    v-model="editedVehicle.muayeneZamani">
                            </div>
                            <div class="mb-3">
                                <label for="kullanilanDepartman" class="form-label">Kullanılan Departman</label>
                                <input type="text" class="form-control" id="kullanilanDepartman"
                                    v-model="editedVehicle.kullanilanDepartman">
                            </div>
                            <div class="mb-3">
                                <label for="yedekAnahtar" class="form-label">Yedek Anahtar Durumu</label>
                                <input type="text" class="form-control" id="yedekAnahtar"
                                    v-model="editedVehicle.yedekAnahtar">
                            </div>
                            <a id="successText">{{ this.successText }}</a>
                            <a id="errorText">{{ this.errorText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit" class="btn btn-primary">Değişiklikleri Kaydet</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script scoped>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'


import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import ExcelJS from 'exceljs'

DataTable.use(DataTableLength)


DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)

export default {
    data() {
        return {
            vehicles: [],
            usersName: '',
            selectedVehicle: null,
            editedVehicle: {},
            successText: '',
            errorText: '',
            yearOptions: [],
            selectedYear: null,
            selectedPeriod: null,
            selectedPaymentDate: null,
            columns: [
                { title: 'id', data: (data, type, row, meta) => meta.row + 1 },
                { title: 'Araç Plakası', data: 'plaka' },
                { title: 'Araç Şasi Numarası', data: 'chassisNumber' },
                { title: 'Araç numarası', data: 'deviceID' },
                { title: 'Marka', data: 'marka' },
                {
                    title: 'Kaydı Düzenle',
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>'
                    }
                },
                {
                    title: 'Kaydı Sil',
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>'
                    }
                },
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,
                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', {
                        text: 'Excel Aktar', // Buton metni
                        action: this.exportVehiclesToExcel // Excel'e aktarma işlevi
                    },
                ],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',
                },
            }
        }
    },
    components: {
        SideBar,
        NavBar,
        DataTable,
    },
    mounted() {
        this.getVehicles()
        // burada datatable edit ve delete butonlarının click olaylarını  dinleyen bir event dinleyicisi oluşturuyoruz.
        const self = this
        $('#vehicleDatatable').on('click', '.edit-button', function () {

            const vehicleId = $(this).data('id')
            const vehicle = self.vehicles.find(vehicle => vehicle.id === vehicleId)
            if (vehicle) {
                self.selectedVehicle = vehicle
                self.editedVehicle = { ...vehicle }
                $('#vehicleModel').modal('show')
            }
        })
        $('#vehicleDatatable').on('click', '.delete-button', function () {
            const vehicleId = $(this).data('id')
            const isConfirmed = confirm("Aracı silmek istediğinizden emin misiniz? Araca ait her türlü kayıt silinecektir!!!")

            // Kullanıcı "Tamam" butonuna tıklarsa işlemi gerçekleştir
            if (isConfirmed) {
                const isConfirmed2 = confirm("Silmek istediğinize eminmisiz?")
                if (isConfirmed2) {
                    self.deleteVehicle(vehicleId)
                }
            }
        })
        $('#vehicleModel').on('hidden.bs.modal', () => {
            this.errorText = ''
            this.successText = ''
        })

    },

    methods: {
        getVehicles() {
            axios.get('get-vehicle', { headers: { 'X-Requested-With': 'vehicleView' } })
                .then(response => {
                    this.vehicles = response.data.data
                })
                .catch(error => {
                    console.log(error.message)
                })
        },
        async saveChanges() {

            const response = await axios.post('update-vehicle', this.editedVehicle)
            if (response.status === 200) {
                this.errorText = ''
                this.successText = response.data.message
                this.getVehicles()
            }
            else if (response.status === 203) {
                this.successText = null
                this.errorText = response.data.message
            }
            else if (response.status === 204) {
                this.successText = null
                this.errorText = 'Bir hata oluştu!'
            }
        },
        async deleteVehicle(vehicleId) {
            try {
                const response = await axios.delete(`delete-vehicle/${vehicleId}`)

                if (response.status === 200) {

                    alert(response.data.message)
                    this.getVehicles()

                } else {
                    alert(response.data.message)
                }
            } catch (error) {
                alert(error.message)
            }
        },
        async exportVehiclesToExcel() {
            if (this.vehicles.length === 0) {
                return
            }
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Araçlar')
            // Başlık sütunlarını ekleyin
            const headerRow = worksheet.addRow(Object.keys(this.vehicles[0]))
            headerRow.eachCell(cell => {
                cell.font = { bold: true }
            })

            this.vehicles.forEach(vehicle => {
                const rowValues = Object.values(vehicle)
                worksheet.addRow(rowValues)
            })

            // Dosyayı indirilebilir bir Blob olarak kaydedin
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    const url = window.URL.createObjectURL(blob)

                    // Excel dosyasını indirme bağlantısını oluşturun
                    const a = document.createElement('a')
                    a.href = url
                    a.download = 'araclar.xlsx' // Excel dosyasının adını burada ayarlayabilirsiniz
                    a.click()

                    // URL'yi temizleyin
                    window.URL.revokeObjectURL(url)
                })
                .catch(error => {
                    console.error('Excel dosyası oluşturulurken hata oluştu:', error)
                })
        }
    },
}
</script>
<style scoped>
#vehiclesTable {
    width: 100%;
}

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.container {
    min-height: 650px;
}

#successText {
    margin-left: 30px;
    color: green;
}

#errorText {
    margin-left: 30px;
    color: red;
}
</style>