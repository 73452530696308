<template>
  <!--  Body Wrapper -->

  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <!-- Sidebar Start -->
    <SideBar />
    <NavBar />
    <br><br><br><br>
    <div class="Profile">
      <div class="container">
        <h2>Profilim</h2>
        <form>
          <label>Kullanıcı Adı</label>
          <input class="form-control" type="text" v-model="user.name" name="plaka" readonly>

          <label>E-Mail Adresi</label>
          <input class="form-control" type="email" v-model="user.email" name="chassisNumber" readonly>
        </form>
      </div>
    </div>
  </div>
</template>
<script scoped>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import VueJwtDecode from 'vue-jwt-decode'
import axios from '@/server/axios'
import app from '@/main'

export default {
  components: {
    SideBar,
    NavBar,
  },
  data() {
    return {
      user: {
        name: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).name,
        email:VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).email,
      },
      
    };
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>

@media (min-width: 1200px) {
  .Profile {
    width: calc(100% - 270px);
    left: 270px;
  }
}

@media (max-width: 1199px) {
  .Profile {
    width: 100%;
  }
}
</style>