<template>
    <div class="taxesform">
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <SideBar />
            <NavBar />
            <br><br>
            <div class="App">
                <div class="container">
                    <h2>Kasko İşlemleri</h2>
                    <form @submit.prevent="">
                        <div class="form-group">
                            <label for="vehicle">Araç Seç:</label>
                            <select v-model="selectedVehicle">
                                <option value="">Araç Seçiniz</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="information">Kasko
                                Bilgileri</button>
                            <button type="button" class="btn btn-info" @click="add">Kasko Bilgisi Ekle</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal taxe -->
        <div class="modal fade" id="kaskoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Kasko Bilgileri</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <DataTable :data="kasko" :columns="columns" class="table table-striped table-bordered display"
                            id="kaskoDatatable" :options="dataTableOptionsWithButtons">
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="kaskoEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Bilgileri Düzenle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="editedKasko.id">
                            <div class="mb-3">
                                <label for="kaskoCompany" class="form-label">Kasko Şirketi</label>
                                <input type="text" v-model="editedKasko.kaskoCompany" required>
                            </div>
                            <div class="mb-3">
                                <label for="year" class="form-label">Kasko Yılı</label>
                                <VueDatePicker v-model="editedKasko.year" year-picker required/>
                            </div>
                            <div class="mb-3">
                                <label for="startDate" class="form-label">Kasko Başlangıç Tarihi</label>
                                <VueDatePicker v-model="editedKasko.startDate" :enable-time-picker="false"
                                    :min-date="minDate" :max-date="maxDate" :format="format" required/>
                            </div>
                            <div class="mb-3">
                                <label for="finishDate" class="form-label">Kasko Bitiş Tarihi</label>
                                <VueDatePicker v-model="editedKasko.finishDate" :enable-time-picker="false"
                                    :min-date="minDate" :max-date="maxDateFinish" :format="format" required/>
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Değişiklikleri Kaydet</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="kaskoAddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Kasko Bilgisi Ekle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="addButton" v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="year" class="form-label">Kasko Şirketi</label>
                                <input type="text" v-model="addKasko.kaskoCompany" required>
                            </div>
                            <div class="mb-3">
                                <label for="year" class="form-label">Kasko Yılı</label>
                                <VueDatePicker v-model="addKasko.year" year-picker required />
                            </div>
                            <div class="mb-3">
                                <label for="startDate" class="form-label">Kasko Başlangıç Tarihi</label>
                                <VueDatePicker v-model="addKasko.startDate" :enable-time-picker="false"
                                    :min-date="minDateAddModal" :max-date="maxDateAddModal" :format="format" required />
                            </div>
                            <div class="mb-3">
                                <label for="finishDate" class="form-label">Kasko Bitiş Tarihi</label>
                                <VueDatePicker v-model="addKasko.finishDate" :enable-time-picker="false"
                                    :min-date="minDateAddModal" :max-date="maxDateFinishAddModal" :format="format"
                                    required />
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Bilgileri Ekle</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            kasko: [],
            editedKasko: [],
            addKasko: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            minDate: null,
            maxDate: null,
            maxDateFinish: null,
            minDateAddModal: null,
            maxDateAddModal: null,
            maxDateFinishAddModal: null,
            format: null,
            isSuccess: true,
            oldValue: null,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'ID' },
                { data: 'plate', title: 'Araç Plakası' },
                { data: 'kaskoCompany', title: 'Kasko Şirketi' },
                { data: 'year', title: 'Yıl' },
                { data: 'startDate', title: 'Başlangıç Tarihi' },
                { data: 'finishDate', title: 'Bitiş Tarihi' },
                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',

                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    watch: {
        'addKasko.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            this.addKasko.startDate = null
            this.addKasko.finishDate = null
        },
        'editedKasko.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            if (this.oldValue !== newValue) {
                this.editedKasko.startDate = null
                this.editedKasko.finishDate = null
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.minDateAddModal = computed(() => new Date(this.addKasko.year, 0, 1))
        this.maxDateAddModal = computed(() => new Date(this.addKasko.year, 11, 31))
        this.maxDateFinishAddModal = computed(() => new Date(this.addKasko.year + 1, 11, 31))
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#kaskoDatatable').on('click', '.edit-button', function () {
            const kaskoID = $(this).data('id')
            const ins = self.kasko.find(ins => ins.id === kaskoID)
            if (ins) {
                self.editedKasko.id = ins.id
                self.editedKasko.year = ins.year
                self.editedKasko.startDate = ins.startDate
                self.editedKasko.carID = ins.carID
                self.editedKasko.plate = ins.plate
                self.editedKasko.finishDate = ins.finishDate
                self.editedKasko.kaskoCompany = ins.kaskoCompany
                self.minDate = computed(() => new Date(self.editedKasko.year, 0, 1))
                self.maxDate = computed(() => new Date(self.editedKasko.year, 11, 31))
                self.maxDateFinish = computed(() => new Date(self.editedKasko.year + 1, 11, 31))
                self.saveChangesText = null
                self.oldValue = ins.year
                $('#kaskoEditModal').modal('show')
            }
        })
        $('#kaskoDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.delete(insID);
        })

    },
    methods: {

        async getVehicles() {
            await axios.get('get-vehicle', { headers: { 'X-Requested-With': 'kaskoVehicle' } })
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    alert(error.message);
                });
        },
        information() {
            axios.post('information', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.kasko = response.data.data
                        $('#kaskoModal').modal('show')
                    }
                    else if (response.status === 203) {
                        $('#kaskoModal').modal('hide')
                        this.formText = response.data.message
                    }

                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {

            const editedKaskoCopy = { ...this.editedKasko }
            await axios.post('update', editedKaskoCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                        this.information()
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async delete(insID) {
            await axios.delete(`delete/${insID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.information()
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message)
                })
        },
        add() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#kaskoAddModal').modal('show')
            }
            else {
                this.formText = "Araç Seçiniz!"
            }

        },
        async addButton() {
            const addKaskoCopy = { ...this.addKasko }
            addKaskoCopy.carID = this.selectedVehicle.id
            addKaskoCopy.numberPlate = this.selectedVehicle.plaka
            await axios.post('add', addKaskoCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        }

    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   