<template>
    <div class="taxesform">
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <SideBar />
            <NavBar />
            <br><br>
            <div class="App">
                <div class="container">
                    <h2>Bakım İşlemleri</h2>
                    <form>
                        <div class="form-group">
                            <label for="vehicle">Araç Seç:</label>
                            <select v-model="selectedVehicle">
                                <option value="">Araç Seçiniz</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="information">Bakım
                                Bilgileri</button>
                            <button type="button" class="btn btn-info" @click="add">Bakım Bilgisi Ekle</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Maintenance -->
        <div class="modal fade" id="maintenanceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Bakım Bilgileri</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <DataTable :data="maintenance" :columns="columns" class="table table-striped table-bordered display"
                            id="maintenanceDatatable" :options="dataTableOptionsWithButtons">
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="maintenanceEditModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Bilgileri Düzenle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="editedMaintenance.id">
                            <div class="mb-3">
                                <label for="maintenanceCompany" class="form-label">Bakımı Yapan Servis</label>
                                <input v-model="editedMaintenance.maintenanceCompany" required>
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">Bakımın Yapıldığı Tarih</label>
                                <VueDatePicker v-model="editedMaintenance.date" :enable-time-picker="false"
                                    :format="format" required/>
                            </div>
                            <div class="mb-3">
                                <label for="price" class="form-label">Bakım Tutarı</label>
                                <input v-model="editedMaintenance.price" required>
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Bakımın Yapıldığı KM</label>
                                <input v-model="editedMaintenance.km" required>
                            </div>
                            <div class="mb-3">
                                <label for="note" class="form-label">Bakımın Açıklaması</label>
                                <input v-model="editedMaintenance.note" required>
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Değişiklikleri Kaydet</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="maintenanceAddModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Bakım Bilgisi Ekle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="addButton" v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="maintenanceCompany" class="form-label">Bakımı Yapan Servis</label>
                                <input type="text" v-model="addMaintenance.maintenanceCompany" required>
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">Bakımın Yapıldığı Tarih</label>
                                <VueDatePicker v-model="addMaintenance.date" :enable-time-picker="false" :format="format"
                                    required />
                            </div>
                            <div class="mb-3">
                                <label for="price" class="form-label">Bakım Tutarı</label>
                                <input type="text" v-model="addMaintenance.price" required>
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Bakımın Yapıldığı KM</label>
                                <input type="text" v-model="addMaintenance.km" required>
                            </div>
                            <div class="mb-3">
                                <label for="note" class="form-label">Bakımın Açıklaması</label>
                                <input type="text" v-model="addMaintenance.note" required>
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Bilgileri Ekle</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            maintenance: [],
            editedMaintenance: [],
            addMaintenance: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            format: null,
            isSuccess: true,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'ID' },
                { data: 'plate', title: 'Araç Plakası' },
                { data: 'maintenanceCompany', title: 'Bakımı Yapan Servis' },
                { data: 'date', title: 'Bakım Tarihi' },
                { data: 'km', title: 'Bakım Kilometresi' },
                { data: 'price', title: 'Bakım Tutarı' },
                { data: 'note', title: 'Bakım Açıklaması' },
                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',

                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#maintenanceDatatable').on('click', '.edit-button', function () {
            const maintenanceID = $(this).data('id')
            const ins = self.maintenance.find(ins => ins.id === maintenanceID)
            if (ins) {
                self.editedMaintenance.id = ins.id
                self.editedMaintenance.date = ins.date
                self.editedMaintenance.price = ins.price
                self.editedMaintenance.carID = ins.carID
                self.editedMaintenance.km = ins.km
                self.editedMaintenance.note = ins.note
                self.editedMaintenance.maintenanceCompany = ins.maintenanceCompany
                self.saveChangesText = null
                $('#maintenanceEditModal').modal('show')
            }
        })
        $('#maintenanceDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.delete(insID);
        })

    },
    methods: {
        async getVehicles() {
            await axios.get('get-vehicle', { headers: { 'X-Requested-With': 'maintenanceVehicle' } })
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    alert(error.message);
                });
        },
        async information() {
            await axios.post('information-maintenance', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.maintenance = response.data.data
                        $('#maintenanceModal').modal('show')
                    }
                    else if (response.status === 203) {
                        this.formText = response.data.message
                        $('#maintenanceModal').modal('hide')
                    }
                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {
            const editedMaintenanceCopy = { ...this.editedMaintenance }
            await axios.post('update-maintenance', editedMaintenanceCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                        this.information()
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async delete(insID) {
            await axios.delete(`delete-maintenance/${insID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.information()
                    } else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message)
                })
        },
        add() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#maintenanceAddModal').modal('show')
            }
            else {
                this.formText = "Araç Seçiniz!"
            }

        },
        async addButton() {
            const addMaintenanceCopy = { ...this.addMaintenance }
            addMaintenanceCopy.carID = this.selectedVehicle.id
            await axios.post('add-maintenance', addMaintenanceCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        }

    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   