<template>
    <div class="taxesform">
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <SideBar />
            <NavBar />
            <br><br>
            <div class="App">
                <div class="container">
                    <h2>Lastik İşlemleri</h2>
                    <form>
                        <div class="form-group">
                            <label for="vehicle">Araç Seç:</label>
                            <select v-model="selectedVehicle">
                                <option value="">Araç Seçiniz</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="information">Lastik
                                Bilgileri</button>
                            <button type="button" class="btn btn-info" @click="add">Lastik Bilgisi Ekle</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal taxe -->
        <div class="modal fade" id="wheelModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Lastik Bilgileri</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <DataTable :data="wheel" :columns="columns" class="table table-striped table-bordered display"
                            id="wheelDatatable" :options="dataTableOptionsWithButtons">
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="wheelEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Bilgileri Düzenle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="editedWheel.id">
                            <div class="mb-3">
                                <label for="maintenanceCompany" class="form-label">Lastik Türü</label>
                                <select class="form-select" v-model="editedWheel.type" required>
                                    <option :value="null" selected disabled>Lastik Türü Şeçiniz</option>
                                    <option value="0">Kış Lastiği</option>
                                    <option value="1">Yaz Lastiği</option>
                                    <option value="2">4 Mevsim Lastiği</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">Değişimin Yapıldığı Tarih</label>
                                <VueDatePicker v-model="editedWheel.date" :enable-time-picker="false" :format="format" required/>
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Değişim KM</label>
                                <input class="form-control" type="text" v-model="editedWheel.km" required>
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Değişiklikleri Kaydet</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="wheelAddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Lastik Bilgisi Ekle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="addButton" v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="maintenanceCompany" class="form-label">Lastik Türü</label>
                                <select class="form-select" v-model="addWheel.type" required>
                                    <option :value="null" selected disabled>Lastik Türü Şeçiniz</option>
                                    <option value="0">Kış Lastiği</option>
                                    <option value="1">Yaz Lastiği</option>
                                    <option value="2">4 Mevsim Lastiği</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">Değişimin Yapıldığı Tarih</label>
                                <VueDatePicker v-model="addWheel.date" :enable-time-picker="false" :format="format"
                                    required />
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">Değişim KM</label>
                                <input class="form-control" type="text" v-model="addWheel.km" required>
                            </div>
                            <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit">Bilgileri Ekle</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            wheel: [],
            editedWheel: [],
            addWheel: {
                type: null
            },
            saveChangesText: '',
            errorText: '',
            date: null,
            format: null,
            isSuccess: true,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'ID' },
                { data: 'plate', title: 'Araç Plakası' },
                { data: 'date', title: 'Lastik Değişim Tarihi' },
                { data: 'km', title: 'Lastik Değişim Kilometresi' },
                {
                    data: 'type', title: 'Lastik Türü',// Doğrudan 'type' özelliğini kullanın
                    render: (data, type, row) => this.getTypeLabel(data)
                },
                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',
                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#wheelDatatable').on('click', '.edit-button', function () {
            const wheelID = $(this).data('id')
            const ins = self.wheel.find(ins => ins.id === wheelID)
            if (ins) {
                self.editedWheel.id = ins.id
                self.editedWheel.date = ins.date
                self.editedWheel.carID = ins.carID
                self.editedWheel.km = ins.km
                self.editedWheel.type = ins.type
                self.saveChangesText = null
                $('#wheelEditModal').modal('show')
            }
        })
        $('#wheelDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.delete(insID);
        })

    },
    methods: {

        async getVehicles() {
            await axios.get('get-vehicle', { headers: { 'X-Requested-With': 'maintenanceVehicle' } })
                .then(response => {
                    if (response.status === 200) {
                        this.vehicles = response.data.data;
                    } else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message);
                });
        },
        async information() {

            await axios.post('information-wheel', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.wheel = response.data.data
                        $('#wheelModal').modal('show')
                    }
                    else if (response.status === 203) {
                        this.formText = response.data.message
                        $('#wheelModal').modal('hide')
                    }
                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {

            const editedWheelCopy = { ...this.editedWheel }
            await axios.post('update-wheel', editedWheelCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                        this.information()
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async delete(insID) {
            await axios.delete(`delete-wheel/${insID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.information()
                    } else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message)
                })
        },
        add() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#wheelAddModal').modal('show')
            }
            else {
                this.formText = "Araç Seçiniz!"
            }

        },
        async addButton() {
            const addWheelCopy = { ...this.addWheel }
            addWheelCopy.carID = this.selectedVehicle.id
            await axios.post('add-wheel', addWheelCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = response.data.message
                    }
                    else if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        getTypeLabel(type) {
            switch (type) {
                case 0:
                    return 'Kış Lastiği';
                case 1:
                    return 'Yaz Lastiği';
                case 2:
                    return '4 Mevsim Lastik';
                default:
                    return '';
            }
        },

    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   