import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'
import router from '@/index'; // Vue Router'ı içe aktarın

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(config => {
  const token = app.config.globalProperties.$cookies.get('authToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    const currentTimestamp = Math.floor(Date.now() / 1000)
    if (currentTimestamp > VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).exp) {
      app.config.globalProperties.$cookies.remove('authToken')
      router.push({ path: '/', query: { error: 'Oturumunuz zaman aşımına uğramıştır.' } })
    }
  }

  return config;
}, error => {
  return Promise.reject(error)
});

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  console.log(error.message)
  // app.config.globalProperties.$cookies.remove('authToken')
  // router.push({ path: '/', query: { error: error.message } })
  return Promise.reject(error)
});

export default axiosInstance
