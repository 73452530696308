<template>
  <div class="Arackayit">
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <!-- Sidebar Start -->
      <SideBar />
      <NavBar />
      <br><br><br><br>
      <!--  Sidebar End -->
      <!--  Main wrapper -->
      <div class="body-wrapper">
        <div class="container">
          <h2>Araç Kayıt Formu</h2>
          <form @submit.prevent="vehicleCreate">
            <label for="user">Kullanıcı Seç:</label>
            <select class="form-select" v-model="vehicle.userID" required>
              <option value="">Select User</option>
              <option v-for="user in users" :key="user.userID" :value="user.userID">{{ user.email }}</option>
            </select>
            <label>Plaka No: (Plaka yazarken boşluk bırakmayınız!)</label>
            <input type="text" v-model="vehicle.plaka" name="plaka" required>

            <label>Şasi No:</label>
            <input type="text" v-model="vehicle.chassisNumber" name="chassisNumber" required>

            <label>Cihaz ID No:</label>
            <input type="text" v-model="vehicle.deviceID" name="deviceID" required>

            <div class="form-group">
              <button type="submit">{{ this.submitValue }}</button>
            </div>

            <div class="form-group">
              <a id="successText">{{ this.successText }}</a>
              <a id="errorText">{{ this.errorText }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
export default {
  components: {
    SideBar,
    NavBar,
  },
  data() {
    return {
      vehicle: {
        chassisNumber: '',
        deviceID: '',
        plaka: ''
      },
      successText: '',
      errorText: '',
      submitValue: 'Kaydet',
      users: []
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      axios.get('usersInformation', {headers:{'X-Requested-With': 'createVehicle'}})
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    vehicleCreate() {
      axios.post('createVehicle', this.vehicle)
        .then(
          ({ data }) => {
            this.submitValue = 'Please wait..'
            try {
              if (data.status === true) {
                this.errorText = ''
                this.successText = 'Kayıt başarı ile oluşturuldu'
                this.vehicle.chassisNumber = ''
                this.vehicle.deviceID = ''
                this.vehicle.plaka = ''
                this.submitValue = 'Kaydet'
              } else {
                this.successText = ''
                this.errorText = data.message
                this.submitValue = 'Kaydet'
              }
            } catch (err) {
              this.errorText = data.message
              this.submitValue = 'Kaydet'
            }
          }
        )
        .catch(error => {
          console.log(error.message);
        });

    }
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}
</style>