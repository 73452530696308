<template>
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
        <div class="users">
            <SideBar />
            <NavBar />
            <br /><br /><br /><br>
            <div class="body-wrapper">
                <div class="container">
                    <DataTable :data="VehiclesUsers" :columns="columns" class="table table-striped table-bordered display"
                        id="VehicleUserDatatable" :options="dataTableOptionsWithButtons">
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from "@/components/admin/Sidebar.vue"
import NavBar from "@/components/admin/Navbar.vue"
import axios from "@/server/axios"
import DataTable from 'datatables.net-vue3'

import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'

DataTable.use(DataTableLength)


DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)
export default {
    data() {
        return {
            VehiclesUsers: [],

            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'id' },
                { data: "email", title: 'Kullanıcı E-mail Adresi' },
                { data: "plate", title: 'Araç Plakası' }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],


                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',

                },


            },
        }
    },
    components: {
        SideBar,
        NavBar,
        DataTable,
    },


    mounted() {
        this.getVehicles()
    },
    methods: {
        getVehicles() {
            axios.get("getVehicleUser", { headers: { 'X-Requested-With': 'vehicleUserView' } })
                .then(response => {
                    this.VehiclesUsers = response.data

                })
                .catch((error) => {
                    console.log(error.message)
                })
        },
    },
}
</script>
  
<style scoped>
#vehiclesTable {
    width: 100%;
}

.container {
    min-height: 650px;
}
</style>
  