<template>
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
        <div class="users">
            <SideBar />
            <NavBar />
            <br><br><br><br>
            <div class="body-wrapper">
                <div class="container">
                    <DataTable :data="users" :columns="Columns" class="table table-striped table-bordered display"
                        id="userDatatable" :options="dataTableOptionsWithButtons">
                    </DataTable>
                </div>
            </div>
        </div>
        <!-- Modal user -->
        <div class="modal fade" id="userModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Kullanıcı Detayları</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="users">
                            <div class="mb-3">
                                <label for="ad" class="form-label">Ad</label>
                                <input type="text" class="form-control" id="ad" v-model="editedUser.ad" required>
                            </div>
                            <div class="mb-3">
                                <label for="soyad" class="form-label">Soyad</label>
                                <input type="text" class="form-control" id="soyad" v-model="editedUser.soyad" required>
                            </div>
                            <div class="mb-3">
                                <label for="sabitNumara" class="form-label">Sabit Numara</label>
                                <input type="text" class="form-control" id="sabitNumara" v-model="editedUser.sabitNumara"
                                    required>
                            </div>

                            <div class="mb-3">
                                <label for="cepTelefonu">Cep Telefonu</label>
                                <MazPhoneNumberInput v-model="editedUser.cepTelefonu" show-code-on-list color="info"
                                    :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB', 'TR']" :ignored-countries="['AC']"
                                    @update="results = $event" :success="results && isValid" required />
                            </div>
                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control" id="email" v-model="editedUser.email" required>
                            </div>
                            <div class="mb-3">
                                <label for="vergiDairesi" class="form-label">Vergi Dairesi</label>
                                <input type="text" class="form-control" id="vergiDairesi" v-model="editedUser.vergiDairesi"
                                    required>
                            </div>
                            <div class="mb-3">
                                <label for="vergiNumarasi" class="form-label">Vergi Numarası</label>
                                <input type="text" class="form-control" id="vergiNumarasi"
                                    v-model="editedUser.vergiNumarasi" required>
                            </div>
                            <div class="mb-3">
                                <label for="adres" class="form-label">Adres </label>
                                <input type="text" class="form-control" id="adres" v-model="editedUser.adres" required>
                            </div>
                            <div class="row" id="locationDropdown">
                                <div class="col-md-4">
                                    <label for="ülke" class="form-label">Ülke</label>
                                    <select class="form-select" id="country" v-model="editedUser.ulke" @change="getStates"
                                        reqired>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{
                                            country.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="Bölge/Şehir" class="form-label">Şehir</label>
                                    <select class="form-select" id="state" v-model="editedUser.il" @change="getCities"
                                        required>
                                        <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="İlçe" class="form-label">İlçe</label>
                                    <select class="form-select" id="city" v-model="editedUser.ilce">
                                        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="postaKodu" class="form-label">Posta Kodu</label>
                                <input type="text" class="form-control" id="postaKodu" v-model="editedUser.postaKodu"
                                    required>
                            </div>
                            <a id="successText">{{ this.successText }}</a>
                            <a id="errorText">{{ this.errorText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit" class="btn btn-primary">Değişiklikleri Kaydet</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
        <!-- Parola Değiştir Modal -->
        <div class="modal fade" id="changePasswordModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Parola Değiştir</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="changePassword">
                            <div class="mb-3">
                                <label for="newPassword" class="form-label">Yeni Parola</label>
                                <input :type="editedPassword.showPassword ? 'text' : 'password'" class="form-control"
                                    id="newPassword" v-model="editedPassword.newPassword" required/>
                            </div>
                            <div class="mb-3">
                                <label for="confirmPassword" class="form-label">Yeni Parola Tekrar</label>
                                <input :type="editedPassword.showPassword ? 'text' : 'password'" class="form-control"
                                    id="confirmPassword" v-model="editedPassword.confirmPassword" required/>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="showPassword"
                                    v-model="editedPassword.showPassword" />
                                <label class="form-check-label" for="showPassword">Parolayı Göster</label>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit" class="btn btn-primary">Değiştir</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import ExcelJS from 'exceljs'
DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)

export default {
    data() {
        return {
            users: [],
            selectedUser: null,
            results: false,
            isValid: false,
            editedUser: {},
            countries: [],
            states: [],
            cities: [],
            successText: null,
            errorText: null,
            editedPassword: {
                newPassword: null,
                confirmPassword: null,
                showPassword: false,
            },

            Columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'id' },
                { data: 'ad', title: 'Kullanıcı Adı' },
                { data: 'soyad', title: 'Kullanıcı Soyadı' },
                { data: 'email', title: 'Email Adresi' },
                { data: 'sirketUnvani', title: 'Şirket Ünvanı' },
                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Parolayı Değiştir',
                    render: (data, type, row) => {
                        return '<button class="bi bi-lock password-button" data-id=" ' + row.userID + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.userID + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,
                lengthMenu: [10, 25, 50, 100],
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy',
                    {
                        text: 'Excel Aktar', // Buton metni
                        action: this.exportToExcel // Excel'e aktarma işlevi
                    }
                ],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',
                },
            }
        }
    },
    components: {
        SideBar,
        NavBar,
        MazPhoneNumberInput,
        DataTable,
    },
    mounted() {
        this.getUsers()

        const self = this;
        $('#userDatatable').on('click', '.edit-button', function () {
            const userID = $(this).data('id')
            const user = self.users.find(user => user.id === userID)
            if (user) {
                self.selectedUser = user;
                self.editedUser = { ...user }
                self.getCountries()
                self.getStates()
                self.getCities()
                $('#userModel').modal('show');
            }
        });

        $('#userDatatable').on('click', '.password-button', function () {
            const userId = $(this).data('id')
            self.openChangePasswordModal(userId)
        });


        $('#userDatatable').on('click', '.delete-button', function () {
            const userID = $(this).data('id')
            const isConfirmed = confirm("Kullanıcıyı silmek istediğinizden emin misiniz? Kullanıcıya ait şoför bilgileri, araç bilgileri ve her türlü kayıt silinecektir!!!");

            // Kullanıcı "Tamam" butonuna tıklarsa işlemi gerçekleştir
            if (isConfirmed) {
                const isConfirmed2 = confirm("Silmek istediğinize eminmisiz?");
                if (isConfirmed2) {
                    self.deleteUser(userID);
                }
            }
        });

    },
    methods: {
        getUsers() {
            axios.get('usersInformation', { headers: { 'X-Requested-With': 'usersView' } })
                .then(response => {
                    if (response.status === 200) {
                        this.users = response.data
                    }
                })
                .catch(error => {
                    alert(error.message)
                });
        },
        getCountries() {
            axios.get('countries')
                .then(response => {
                    this.countries = response.data
                })
                .catch(error => {
                    console.log(error.message)
                });
        },
        getStates() {
            axios.get('states/' + this.editedUser.ulke)
                .then(response => {
                    this.states = response.data
                    this.cities = [];
                })
                .catch(error => {
                    console.log(error.message)
                });
        },
        getCities() {
            axios.get('cities/' + this.editedUser.il)
                .then(response => {
                    this.cities = response.data
                })
                .catch(error => {
                    console.log(error.message)
                });
        },
        async saveChanges() {
            this.successText = null
            if (this.results.isValid === false) {
                this.errorText = 'Phone number format error!'
            } else {
                await axios.post('update-user', this.editedUser)
                    .then(response => {
                        if (response.status === 200) {
                            this.getUsers()
                            this.errorText = null
                            this.successText = 'Değişiklikler başarıyla uygulandı.'
                        }
                        if (response.status === 203) {
                            this.errorText = response.data.message
                            this.successText = null
                        }

                    })
                    .catch(error => {
                        this.successText = null
                        this.errorText = error.message
                    });
            }
        },
        async deleteUser(userID) {
            await axios.delete(`delete-user/${userID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.getUsers()
                        alert(response.data.message)
                    }
                    else if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error.message)
                });

        },
        openChangePasswordModal(userId) {
            this.editedPassword.userID = userId; // Şifre değiştirilecek kullanıcının ID'sini ayarla
            this.editedPassword.newPassword = null;
            this.editedPassword.confirmPassword = null;
            $('#changePasswordModal').modal('show');
        },
        async changePassword() {
            await axios.post(
                'change-password', this.editedPassword
            )
                .then(response => {
                    if (response.status === 200) {
                        alert(response.data.message)
                        $('#changePasswordModal').modal('hide')
                    }
                    if (response.status === 203) {
                        alert(response.data.message)
                    }

                })
                .catch(error => {
                    alert(error.message)
                });
        },
        exportToExcel() {
            if (this.users.length === 0) {
                return;
            }

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Kullanıcılar');
            // Başlık sütunlarını ekleyin
            const headerRow = worksheet.addRow(Object.keys(this.users[0]));
            headerRow.eachCell(cell => {
                cell.font = { bold: true };
            });
            // Kullanıcı verilerini ekleyin
            this.users.forEach(user => {
                worksheet.addRow(Object.values(user));
            });
            // Dosyayı indirilebilir bir Blob olarak kaydedin
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);
                    // Excel dosyasını indirme bağlantısını oluşturun
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'kullanicilar.xlsx';
                    a.click();
                    // URL'yi temizleyin
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error('Excel dosyası oluşturulurken hata oluştu:', error);
                });
        }
    },
}

</script>
  
<style scoped>

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.container {
    min-height: 650px;    
}

#successText {
    margin-left: 30px;
    color: green;
}

#errorText {
    margin-left: 30px;
    color: red;
}</style>
  