<template>
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
        <div class="users">
            <SideBar />
            <NavBar />
            <br /><br /><br /><br>
            <div class="body-wrapper">
                <div class="container">
                    <DataTable :data="CarDriver" :columns="columns" class="table table-striped table-bordered display"
                        id="CarDriverDatatable" :options="dataTableOptionsWithButtons">
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from "@/components/admin/Sidebar.vue"
import NavBar from "@/components/admin/Navbar.vue"
import axios from "@/server/axios"
import DataTable from 'datatables.net-vue3'

import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'

DataTable.use(DataTableLength)


DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)
export default {
    data() {
        return {
            CarDriver: [],

            columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'id' },
                { data: 'identyID', title: 'T.C. Kimlik Numarası' },
                { data: "name", title: 'Şoför Adı' },
                { data: "lastName", title: 'Şoför SoyAdı' },
                { data: "car_plate", title: 'Araç Plakası' },
                {
                    title: 'Kaydı Sil',
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                },
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy', 'excel',
                ],


                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',

                },


            },
        };
    },
    components: {
        SideBar,
        NavBar,
        DataTable,
    },


    mounted() {
        this.getDriverCar();
        const self = this;
        $('#CarDriverDatatable').on('click', '.delete-button', function () {
            const vehicleId = $(this).data('id');
            self.deleteVehicle(vehicleId);
        });

    },
    methods: {
        getDriverCar() {
            axios.get("driver-car", { headers: { 'X-Requested-With': 'vehicleDriverView' } })
                .then((response) => {
                    if (response.status === 200) {
                        this.CarDriver = response.data.data
                    }
                    else if (response.status === 203) {
                        alert(response.data.message)
                    }
                    else if (response.status === 204) {
                        this.CarDriver = []
                    }
                })
                .catch((error) => {
                    alert(error.message)
                });
        },

        async deleteVehicle(vehicleId) {
            try {
                const response = await axios.delete(`delete-car-driver/${vehicleId}`, { headers: { 'X-Requested-With': 'deleteVehicleDriver' } })

                if (response.status === 200) {
                    this.getDriverCar()
                } else {
                    alert(response.data.message)
                }
            } catch (error) {
                alert(error.message)
            }
        },
    },
};
</script>
  
<style scoped>
#vehiclesTable {
    width: 100%;
}

.container {
    min-height: 650px;
}
</style>
  