<template>
  <!--  Body Wrapper -->
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <!-- Sidebar Start -->
    <SideBar />
    <NavBar />
    <br><br><br><br>
    <!--  Sidebar End -->
    <!--  Main wrapper -->
    <div class="body-wrapper">
      <!--  Header Start -->
      <div class="RegisterPage">
        <!--  Header End -->
        <div class="container">
          <h2>Şoför Ekle</h2>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="user">Müşteri</label>
                  <select class="form-select" v-model="driver.userID" required>
                    <option value="" disabled selected hidden>Select User</option>
                    <option v-for="user in users" :key="user.userID" :value="user.userID">{{ user.email }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="ad">Ad</label>
                  <input v-model="driver.name" type="text" id="ad" name="ad" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="soyad">Soyad</label>
                  <input v-model="driver.lastName" type="text" id="soyad" name="soyad" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="cepTelefonu">Cep Telefonu</label>
                <MazPhoneNumberInput v-model="driver.phoneNumber" show-code-on-list color="info"
                  :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB', 'TR']" :ignored-countries="['AC']"
                  @update="results = $event" :success="results && isValid" required/>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="identyID">T.C. Kimlik Numarası</label>
                  <input v-model="driver.identyID" type="text" id="identyID" name="identyID" required>
                </div>
              </div>

            </div>

            <br>
            <div class="form-group">
              <button type="submit">Şoför Oluştur</button>
            </div>
            <a id="errorText">{{ this.errorText }}</a>
            <a id="successText">{{ this.successText }}</a>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
export default {
  components: {
    SideBar,
    NavBar,
    MazPhoneNumberInput
  },
  data() {
    return {
      users: [],
      phoneNumber: null,
      driver: [],
      errorText: null,
      successText: null,
      results: false,
      isValid: false,
      userID: ''
    };
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      axios.get('usersInformation', { headers: { 'X-Requested-With': 'driverCreate' } })
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    submitForm() {
      this.errorText = null
      this.successText = null
      this.driverCopy = { ... this.driver }
      if (this.results.isValid === false) {
        this.errorText = 'Phone number format error!'
      } else {
        axios.post('create-driver', this.driverCopy)
          .then(
            ({ data }) => {
              this.submitValue = 'Please wait..'
              try {
                if (data.status === true) {
                  this.errorText = null
                  //this.driver = null
                  this.successText = data.message
                } else {
                  this.errorText = data.message
                }
              } catch (err) {
                this.errorText = data.message
              }
            }
          )
          .catch(error => {
            console.log(error.message);
          });
      }

    }
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}

.body-wrapper {
  margin-top: 5%;

}

.RegisterPage {
  margin-bottom: 10%;
}

select {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.205);
  padding: 7px;
}</style>