<template>
  <header class="app-header" style="width: 100%; background-color:#4f73d9;">
    <nav class="navbar navbar-expand-lg navbar-light">
      <ul class="navbar-nav">
        <li class="nav-item d-block d-xl-none">
          <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
            <i class="ti ti-menu-2" style="color:white;"></i>
          </a>
        </li>
        <!--
        <li class="nav-item">
          <a class="nav-link nav-icon-hover" href="javascript:void(0)">
            <i class="ti ti-bell-ringing"></i>
            <div class="notification bg-primary rounded-circle"></div>
          </a>
        </li>
        -->
      </ul>
      <div class="navbar-collapse justify-content-end px-0" id="navbarNav" style="background-color:#4f73d9;">
        <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
          <li class="nav-item dropdown">
            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img src="@/assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle">
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
              <div class="message-body">
                <router-link to="/myProfile" class="sidebar-link" aria-expanded="false">
                <a class="d-flex align-items-center gap-2 dropdown-item">
                  <i class="ti ti-user fs-6"></i>
                  <p class="mb-0 fs-3">Profilim</p>
                </a></router-link>
                
                <router-link to="/adminRegister" class="sidebar-link" aria-expanded="false"><a
                    class="d-flex align-items-center gap-2 dropdown-item">
                    <i class="ti ti-list-check fs-6"></i>
                    <p class="mb-0 fs-3">Admin Oluştur</p>
                  </a></router-link>

                <a class="btn btn-outline-primary mx-3 mt-2 d-block" @click="logout">Logout</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import axios from '@/server/axios'
import app from '@/main'

export default {
  methods: {
    async logout() {
      try {
        await axios.post('logout')
          .then(response => {
            if (response.data.status === "success") {
              app.config.globalProperties.$cookies.remove('authToken')
              this.$router.push('/')
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
      catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    // Admin Panel settings

    //****************************
    /* This is for the mini-sidebar if width is less than 1170*/
    //****************************
    var setsidebartype = function () {
      var width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      if (width < 1199) {
        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
        $("#main-wrapper").addClass("mini-sidebar");
      } else {
        $("#main-wrapper").attr("data-sidebartype", "full");
        $("#main-wrapper").removeClass("mini-sidebar");
      }
    };
    $(window).ready(setsidebartype);
    $(window).on("resize", setsidebartype);
    //****************************
    /* This is for sidebartoggler*/
    //****************************
    $(".sidebartoggler").on("click", function () {
      $("#main-wrapper").toggleClass("mini-sidebar");
      if ($("#main-wrapper").hasClass("mini-sidebar")) {
        $(".sidebartoggler").prop("checked", !0);
        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
      } else {
        $(".sidebartoggler").prop("checked", !1);
        $("#main-wrapper").attr("data-sidebartype", "full");
      }
    });
    $(".sidebartoggler").on("click", function () {
      $("#main-wrapper").toggleClass("show-sidebar");
    });
  }
}
</script>