<template>
	<div class="limiter">
		<div class="container-login100" style="background-image: url('images/bg-01.jpg');">

			<div class="wrap-login100">
				<form class="login100-form validate-form" @submit.prevent="submitForm">
					<div>
						<p id="route-query-error" v-if="$route.query.error">{{ $route.query.error }}</p>

					</div>
					<span class="login100-form-logo">
						<img src="images/logo.png" height="120px">
					</span>
					<br>
					<span class="login100-form-title p-b-34 p-t-27">
						Fleet Admin Log in
					</span>
					<br>
					<div class="wrap-input100 validate-input" data-validate="Enter username">
						<input v-model="user.email" class="input100" type="email" name="username" placeholder="Username"
							required>
						<span class="focus-input100" data-placeholder="&#xf207;"></span>
					</div>

					<div class="wrap-input100 validate-input" data-validate="Enter password">
						<input v-model="user.password" class="input100" :type="passwordVisible ? 'text' : 'password'"
							name="pass" placeholder="Password" required>
						<span class="focus-input100" data-placeholder="&#xf191;"></span>
					</div>

					<div>
						<label for="showPasswordCheckbox" id="showPassword">
							Show Password
							<input type="checkbox" id="showPasswordCheckbox" v-model="passwordVisible">
						</label>
					</div>
					<div>
						<a id="error">{{ this.errorText }}</a>
					</div>
					<br>
					<div class="container-login100-form-btn" style="display:grid; place-items:center;">
						<button class="login100-form-btn">
							{{ this.submitValue }}
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import axios from '@/server/axios'
import app from '@/main'
import router from '@/index'

export default {
	data() {
		return {
			user: {
				email: '',
				password: ''
			},
			errorText: '',
			passwordVisible: false,
			submitValue: 'Login',
		}
	},
	methods: {
		closeModal() {
			this.$emit('close');
		},
		async submitForm() {
			await axios.post('login', this.user)
				.then(
					response => {

						if (response.status === 200) {
							const token = response.data.authorization.token
							this.submitValue = 'Please wait..'
							if (app.config.globalProperties.$cookies.set('authToken', token)) {
								router.push('/admin');
							}
						}
						if (response.status === 203) {
							this.errorText = 'Giriş Bilgilerini Kontrol Ediniz!'
						}
						if (response.status === 204) {
							this.errorText = 'Girilen bilgilere ait kullanıcı bulunamadı!'
						}
					}
				)
				.catch(error => {
					this.errorText = error.message
				})
		}
	}
}
</script>
<style scoped>
#error {
	color: white;
}

#showPassword {
	color: white;
}

#route-query-error {
	color: white;
}
</style>
