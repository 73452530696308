<template>
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
        <div class="users">
            <SideBar />
            <NavBar />
            <br><br><br><br>
            <div class="body-wrapper">
                <div class="container">
                    <DataTable :data="users" :columns="Columns" class="table table-striped table-bordered display"
                        id="userDatatable" :options="dataTableOptionsWithButtons">
                    </DataTable>
                </div>
            </div>
        </div>
        <!-- Modal driver -->
        <div class="modal fade" id="driverModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Şoför Detayları</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveChanges" v-if="users">
                            <div class="mb-3">
                                <label for="ad" class="form-label">Ad</label>
                                <input type="text" class="form-control" id="ad" v-model="editedDriver.name" required>
                            </div>
                            <div class="mb-3">
                                <label for="soyad" class="form-label">Soyad</label>
                                <input type="text" class="form-control" id="soyad" v-model="editedDriver.lastName" required>
                            </div>
                            <div class="mb-3">
                                <label for="sabitNumara" class="form-label">T.C. Kimlik Numarası</label>
                                <input type="text" class="form-control" id="sabitNumara" v-model="editedDriver.identyID"
                                    required>
                            </div>

                            <div class="mb-3">
                                <label for="cepTelefonu">Cep Telefonu</label>
                                <MazPhoneNumberInput v-model="editedDriver.phoneNumber" show-code-on-list color="info"
                                    :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB', 'TR']" :ignored-countries="['AC']"
                                    @update="results = $event" :success="results && isValid" required/>
                            </div>
                            <a id="successText">{{ this.successText }}</a>
                            <a id="errorText">{{ this.errorText }}</a>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                                <button type="submit" class="btn btn-primary">Değişiklikleri
                                    Kaydet</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import ExcelJS from 'exceljs'
DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)
export default {
    data() {
        return {
            results: false,
            isValid: false,
            users: [],
            editedDriver: [],
            successText: null,
            errorText: null,
            Columns: [
                { data: (data, type, row, meta) => meta.row + 1, title: 'id' },
                { data: 'name', title: 'Şoför Adı' },
                { data: 'lastName', title: 'Şoför Soyadı' },
                { data: 'identyID', title: 'T.C. Kimlik Numarası' },
                { data: 'phoneNumber', title: 'Telefon Numarası' },
                { data: 'companyName', title: 'Şirket İsmi' },
                // Modal içindeki cep telefonu bilgisini temsil eden sütun

                {
                    data: null,
                    title: 'Kaydı Düzenle',
                    render: (data, type, row) => {
                        return '<button class="bi bi-gear edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    title: 'Kaydı Sil',
                    render: (data, type, row) => {
                        return '<button class="bi bi-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,
                lengthMenu: [10, 25, 50, 100],
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    'copy',
                    {
                        text: 'Excel Aktar', // Buton metni
                        action: this.exportToExcel // Excel'e aktarma işlevi
                    }
                ],
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json',
                },
            }
        }
    },
    components: {
        SideBar,
        NavBar,
        MazPhoneNumberInput,
        DataTable,
    },
    mounted() {
        this.getDrivers()

        const self = this;
        $('#userDatatable').on('click', '.edit-button', function () {
            const driverID = $(this).data('id')
            const driver = self.users.find(driver => driver.id === driverID)
            if (driver) {
                const { id, identyID, lastName, name, phoneNumber } = driver
                self.editedDriver = { id, identyID, lastName, name, phoneNumber }
                $('#driverModal').modal('show')
            }
        })

        $('#userDatatable').on('click', '.delete-button', function () {
            const driverID = $(this).data('id')
            self.deleteDriver(driverID);
        })



    },
    methods: {
        getDrivers() {
            axios.get('drivers', { headers: { 'X-Requested-With': 'driverView' } })
                .then(response => {
                    if (response.status === 200) {
                        this.users = response.data.data

                    }
                    else {
                        console.log(response.data.message)
                    }

                })
                .catch(error => {
                    console.log(error.message)
                });
        },
        async saveChanges() {
            this.successText = null
            if (this.results.isValid === false) {
                this.errorText = 'Phone number format error!'
            } else {
                await axios.post('update-driver', this.editedDriver)
                    .then(response => {
                        if (response.status === 200) {
                            this.getDrivers()
                            this.errorText = null
                            this.successText = 'Şoför bilgileri başarıyla güncellendi.'
                        }
                        else if (response.status === 203) {
                            this.errorText = response.data.message
                            this.successText = null
                        }
                        else if (response.status === 204) {
                            this.errorText = 'Şoför bilgileri güncellenirken hata oluştu!.'
                            this.successText = null
                        }
                    })
                    .catch(error => {
                        this.successText = null
                        this.errorText = error.message
                    })
            }
        },
        async deleteDriver(driverID) {
            await axios.delete(`delete-driver/${driverID}`)
                .then(response => {
                    if (response.status === 200) {
                        alert(response.data.message)
                        this.getDrivers()
                    }
                    else if (response.status === 203) {
                        alert(response.data.message)
                    }
                    else if (response.status === 204) {
                        alert('Şoför kaldırılırken bir hata oluştu')
                    }
                })
                .catch(error => {
                    alert(error.message)
                });

        },
        exportToExcel() {
            if (this.users.length === 0) {
                return;
            }

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Drivers');
            // Başlık sütunlarını ekleyin
            const headerRow = worksheet.addRow(Object.keys(this.users[0]));
            headerRow.eachCell(cell => {
                cell.font = { bold: true };
            });
            // Şoför verilerini ekleyin
            this.users.forEach(driver => {
                worksheet.addRow(Object.values(driver));
            });
            // Dosyayı indirilebilir bir Blob olarak kaydedin
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);
                    // Excel dosyasını indirme bağlantısını oluşturun
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'kullanicilar.xlsx';
                    a.click();
                    // URL'yi temizleyin
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error('Excel dosyası oluşturulurken hata oluştu:', error);
                });
        }
    },
}

</script>
  
<style scoped>
#usersTable {
    width: 100%;
}

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.container {
    min-height: 650px;
}

#successText {
    margin-left: 30px;
    color: green;
}

#errorText {
    margin-left: 30px;
    color: red;
}
</style>
  